<section class="Mask">
    <div class="container">
        <div class="row d-flex justify-content-center ">
            <div class="col-xl-12">
                <h1 class="f-heading font-weight-bold">Frequently Asked Questions</h1>
                <p class="text-center sub-heading">Helping you to solve your trade queries.</p>
            </div>
        </div>
    </div>
</section>


<section id="services" class="howitworks-color">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                            aria-controls="home" aria-selected="true">TRADE</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                            aria-controls="profile" aria-selected="false">PAYMENT</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                            aria-controls="contact" aria-selected="false">LOGISTICS</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-2 tabFlex">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active mytabcontent" id="home" role="tabpanel"
                        aria-labelledby="home-tab">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseOne">
                                            <i class="fa fa-plus"></i>
                                            Why to Trade on Equibiz?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>Equibiz allows you to connect with the verified users and helps you to conduct transactions with utmost transparency. Equibiz is powered with a unique deal booking experience based on a price discovery model, which ensure best deal every time for our partners. It takes care of everything right from payment assurance, integrated logistic facility, quality control and other value added services so that you optimize your business seated at your desk.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo">
                                            <i class="fa fa-plus"></i>
                                            What are the documents require to register over Equibiz?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>Valid GST certificate (regular not composite),PAN Card, Aadhar Card of Proprietor/Partner/Director/Karta etc and Valid business bank account.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseThree"><i class="fa fa-plus">
                                            </i> How can I start transacting over Equibiz?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>Sign Up using mobile number and email id. Login to your account and complete the registration process. Once the registration request is approved by Equibiz, you can start transacting on equibiz
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingfour">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsefour">
                                            <i class="fa fa-plus"></i>
                                            I am a buyer; how do I book a deal over Equibiz?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsefour" class="collapse" aria-labelledby="headingfour"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>To book a deal, buyer must prefund the wallet with the full deal amount along with platform charges and select the products he wants to buy. After selecting the product, pre book request can be sent to the supplier for approval. Once approved, the deal is confirmed, and buyer now complete the balance payment and delivery of products will be arranged as per the deal terms.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingfive">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsefive">
                                            <i class="fa fa-plus"></i>
                                            I am a seller; how can I list my products over Equibiz?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsefive" class="collapse" aria-labelledby="headingfive"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>The supplier can list his product by selecting deal listing menu at the supplier’s dashboard. The supplier will find the products available for listing and after selecting the product related information’s, supplier can list the product for trade. <br>
                                            Products which are not available for listing, the supplier must place a request by sending an email at <a href="mailto:support@equbiz.in">support@equbiz.in</a> or to call customer support for addition of a new product in the listing menu. After approval, the supplier will be notified and now supplier can list and trade.
                                            </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingsix">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsesix"><i class="fa fa-plus"></i> What is T, T+1 and T+2 date convention at the time of deal listing?</button>
                                    </h2>
                                </div>
                                <div id="collapsesix" class="collapse" aria-labelledby="headingsix"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>This is the date on which stock will be ready at the supplier place. The supplier must carefully choose the option as non-compliance with the supply date will affect the rating and may also lead to cancellation of the deal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingseven">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseseven">
                                            <i class="fa fa-plus"></i>What is minimum order quantity?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseseven" class="collapse" aria-labelledby="headingseven"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>It is the minimum quantity below which the seller is not willing to accept the
                                            order</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingeight">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseeight">
                                            <i class="fa fa-plus"></i>How do I track my order?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseeight" class="collapse" aria-labelledby="headingeight"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>The “My order section” has the complete details of all the orders both
                                            outstanding and
                                            completed.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingnine">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsenine">
                                            <i class="fa fa-plus"></i> 	I am a Seller; how can I upload Invoice and Eway Bill?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsenine" class="collapse" aria-labelledby="headingnine"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>In my order section, there is link available to upload IMEI details, Invoice and Eway bill. In the order section itself, there is information available for Supplier to generate Invoice and Ewaybill. Once uploaded, the supplier has to update status as dispatched. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingten">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseten">
                                            <i class="fa fa-plus"></i> 	I am a Seller; how do I notify that stock is ready for pick up?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseten" class="collapse" aria-labelledby="headingten"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>Once stock is ready at supplier’s place, the ready status of stock must be updated in my order section which will also be updated to the buyer in his/her my order section.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingeleven">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseeleven">
                                            <i class="fa fa-plus"></i>
                                            How can I hold/deactivate my listing?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseeleven" class="collapse" aria-labelledby="headingeleven"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>Under the “My listing section” there is a hold and un hold button along with deactivate in the listing menu. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingtwel">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsetwel">
                                            <i class="fa fa-plus"></i> 	Can I cancel/reschedule the deal?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsetwel" class="collapse" aria-labelledby="headingtwel"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>Yes, but the cancellation and reschedule can be requested as per the Equbiz policy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingthirt">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsethirt">
                                            <i class="fa fa-plus"></i> 	 	I am a seller, what is market preference?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsethirt" class="collapse" aria-labelledby="headingthirt"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>The seller may choose to list the product under retail or wholesale segment depending upon the preferred target segment.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingfourt">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsefourt">
                                            <i class="fa fa-plus"></i>How do I reach to Equibiz for any support?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsefourt" class="collapse" aria-labelledby="headingfourt"
                                    data-parent="#accordionExample">
                                    <div class="card-body p-4 ml-4">
                                        <p>You can call on 022-49698090/ 91+9136446133 Or write to us at <a href="mailto:support@equibiz.in">support@equibiz.in</a></p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <div class="tab-pane fade mytabcontent" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="accordion" id="accordionExample2">
                            <div class="card">
                                <div class="card-header" id="heading1">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse1">
                                            <i class="fa fa-plus"></i>
                                             What is Equibiz account number?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse1" class="collapse" aria-labelledby="heading1"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>Equibiz account Number is the unique number allocated at the time of registration to all registered partners and is mapped to the user’s wallet. The wallet instantly gets updated with the amount transferred in NODAL account using Equibiz account number.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading2">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse2">
                                            <i class="fa fa-plus"></i>
                                            How can I transfer funds in my wallet before transaction?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse2" class="collapse" aria-labelledby="heading2"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>Under Equibiz wallet section, the user specific account details are provided for funding the wallet. Users are requested to use these details to add your Equbiz bank account as a beneficiary in your internet banking. Use your internet banking/branch banking to transfer funds in your wallet using NEFT, RTGS or IMPS options.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading3">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse3"><i class="fa fa-plus">
                                            </i> When platform fees and other chargeswill be deducted from buyers and supplier’s wallet?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse3" class="collapse" aria-labelledby="heading3"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>After acceptance of prebooking request by the seller, the buyer’s wallet will be debited with platform fees, logistic charges,insurance, and any other applicable charges. The charges will be deducted from suppliers account before the final payment of the deal amount.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading4">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse4">
                                            <i class="fa fa-plus"></i>
                                             Do I need to pay for Insurance charges?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse4" class="collapse" aria-labelledby="heading4"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>Insurance charges are optional, but the buyers are advised to take insurance to protect against the potential financial loss in case of a mishap during the transit or at any other place after the product is purchased.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading5">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse5">
                                            <i class="fa fa-plus"></i>
                                            How do we about Equibiz charges?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse5" class="collapse" aria-labelledby="heading5"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>Equibiz charges are very transparent and will be displayed at the time of deal
                                            booking.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading6">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse6"><i class="fa fa-plus"></i> What is the settlement date?</button>
                                    </h2>
                                </div>
                                <div id="collapse6" class="collapse" aria-labelledby="heading6"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>The settlement date referred as T is the date on which the consignment is successfully delivered to the buyer place which includes the time taken to dispatch the stock from supplier place, transit time involved (by air or by surface transport), time involved in arranging delivery of stock to the buyer, etc. In case of a dispute, T is the date of settlement of the dispute and time taken in transit and delivery of stock.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading7">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse7">
                                            <i class="fa fa-plus"></i>
                                             I am a buyer or a seller, what is the deal payment mechanism?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse7" class="collapse" aria-labelledby="heading7"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>Before the start of deal booking, buyer is expected to prefund the wallet with the deal value and all the applicable charges. Upon confirmation of the pre booking order, the seller will receive10% of the deal value in the wallet and charges are parked in the Equibiz wallet. The balance 90% of the deal value received from the buyer will be credited in the supplier’s wallet after deducting all applicable charges and taxes. Once consignment is successfully delivered to the buyer’s address, the entire deal amount less of charges and taxes will be credited within T+1 time to the supplier’s bank account. All the charges and taxes collected from both the buyer and seller will then be transferred in Equbiz bank account.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading8">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse8">
                                            <i class="fa fa-plus"></i> 	I am a seller, how much TDS and TCS is applicable?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse8" class="collapse" aria-labelledby="heading8"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>TDS rate 0.75% on pre-GST amount. This rate is applicable till March 2021 after which it will increase to 1%.TCS rate is 1% on pre-GST amount.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading9">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse9">
                                            <i class="fa fa-plus"></i> 	What is payment timeline for value date today, tomorrow and a day after tomorrow deal?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse9" class="collapse" aria-labelledby="heading9"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>The payment timeline for receiving the full payment in case of all the three value date deals are 10% at pre booking and balance 90% after the deal confirmation on the same day.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading10">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse10">
                                            <i class="fa fa-plus"></i>
                                             	What is convenience fee?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse10" class="collapse" aria-labelledby="heading10"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>Equibiz is charging both buyers and sellers to trade and in turn charges a convenience fee to facilitate the trade. This convenience fee is nonrefundable and in case of cancellation, it is the discretion of equibiz to withhold the convenience fees.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading11">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse11">
                                            <i class="fa fa-plus"></i>
                                            How do I get my convenience fee and other charges invoice?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse11" class="collapse" aria-labelledby="heading11"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>The buyer/seller can download the invoice through account section. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading12">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse12">
                                            <i class="fa fa-plus"></i> 	How do I get my transaction and tax report?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse12" class="collapse" aria-labelledby="heading12"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>The buyer/seller can download the tax report through account section.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading13">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapse13">
                                            <i class="fa fa-plus"></i> 	What about seller GST filing liability?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapse13" class="collapse" aria-labelledby="heading13"
                                    data-parent="#accordionExample2">
                                    <div class="card-body p-4 ml-4">
                                        <p>Although GST filing and payment of tax is an individual liability of both buyer and seller, however, as per Equbiz policy non filing of GST return on timely manner may lead to putting the seller trading account on hold till the successful filing of GST return. Continuous default on GST return filing may permanently barred seller to trade on Eqiubiz
                                        </p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <div class="tab-pane fade mytabcontent" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="accordion" id="accordionExample3">
                            <div class="card">
                                <div class="card-header" id="headingone1">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseone1">
                                            <i class="fa fa-plus"></i>
                                             	What is delivery location when I am booking a deal?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseone1" class="collapse" aria-labelledby="headingone1"
                                    data-parent="#accordionExample3">
                                    <div class="card-body p-4 ml-4">
                                        <p>The buyer has option to take the delivery of the products either at the place of business or at the Equibiz nearest fulfillment centers. Pick up and delivery at the fulfilment centers is free while the local logistic charges will apply in case of using Equibiz logistics for local pickup and delivery.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingtwo2">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsetwo2">
                                            <i class="fa fa-plus"></i>
                                            What is logistic preference when I am booking a deal?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsetwo2" class="collapse" aria-labelledby="headingtwo2"
                                    data-parent="#accordionExample3">
                                    <div class="card-body p-4 ml-4">
                                        <p>Buyer may choose By Air or By Surface Transport as a mode of shipment at time of booking a deal. Expected delivery time in case of Air transport is maximum 48 hrs and in case of surface transport it is 5 to 7 days. Logistic rate card is available to view at the time of deal booking.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingthree3">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsethree3"><i class="fa fa-plus">
                                            </i> What is location of supply (LOS) and expected dispatch date when I am booking a deal?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsethree3" class="collapse" aria-labelledby="headingthree3"
                                    data-parent="#accordionExample3">
                                    <div class="card-body p-4 ml-4">
                                        <p>LOS (Fulfillment Center) is the place where supplier has selected to deliver the products. The expected dispatch date is the date when the products is expected to be dispatched from the LOS which is normally one day after the date of supply.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingfour4">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsefour4">
                                            <i class="fa fa-plus"></i>
                                            I am a seller, what is logistic preference?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsefour4" class="collapse" aria-labelledby="headingfour4"
                                    data-parent="#accordionExample3">
                                    <div class="card-body p-4 ml-4">
                                        <p>The seller can choose to deliver the order at the nearest fulfilment center or get the stocks picked up from his place of business after paying logistic charges. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingfive5">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsefive5">
                                            <i class="fa fa-plus"></i>
                                          Are Equibiz logistic services available for both buyer and seller?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapsefive5" class="collapse" aria-labelledby="headingfive5"
                                    data-parent="#accordionExample3">
                                    <div class="card-body p-4 ml-4">
                                        <p>Yes, at the same time both buyer and seller can use fulfillment centers to pick up and deliver stocks without paying any charges.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingsix6">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapsesix6">
                                            <i class="fa fa-plus"></i> 	What is club deal? How can I book deal under this option?</button>
                                    </h2>
                                </div>
                                <div id="collapsesix6" class="collapse" aria-labelledby="headingsix6"
                                    data-parent="#accordionExample3">
                                    <div class="card-body p-4 ml-4">
                                        <p>The buyer can choose to club the deals purchased at the same LOS (Fulfillment center) and for the same dispatch date to save on logistic charges in case by air option has been selected. Buyer can choose this option at the time of deal booking and logistic will be accordingly arranged to take care of all the purchases and shipped as a single consignment. The buyer must contact equibiz customer care in case he wishes to club the deals for further guidance.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingseven7">
                                    <h2 class="mb-0">
                                        <button type="button" class="btn btn-link btn-font collapsed" data-toggle="collapse"
                                            data-target="#collapseseven7">
                                            <i class="fa fa-plus"></i> 	What is QC and what are the parameters of this?</button>
                                    </h2>
                                </div>
                                <div id="collapseseven7" class="collapse" aria-labelledby="headingseven7"
                                    data-parent="#accordionExample3">
                                    <div class="card-body p-4 ml-4">
                                        <p>The QC is additional facility provided by Equibiz to check that products delivered are as per deal terms at the time of pick up from the supplier. However, the responsibility to provide products as per deal terms squarely lies with the supplier. Following are the QC parameters for mobile phones:</p>
                                        <div class="mt-5"> 
                                            <table class="table">
                                                <thead>
                                                    <th>Fresh Mobile Phones</th>
                                                    <th>Activated Mobile Phones</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Properly Sealed</td>
                                                        <td>Mobil phone inside the box</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Each mobile box is in original condition</td>
                                                        <td>Each mobile box is in original condition</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Brand with Model No</td>
                                                        <td>Brand with Model No</td>
                                                    </tr>
                                                    <tr>
                                                        <td>RAM/Internal Storage</td>
                                                        <td>RAM/Internal Storage</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quantity</td>
                                                        <td>Quantity</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color (As per deal terms)</td>
                                                        <td>Color (As per deal terms)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Whether the product is properly packed</td>
                                                        <td>Whether the product is properly packed</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>

<!-- <section id="tradeId">
    <div class="container trade">
        <div class="row">
            <div class="col-sm-12">
                <div class="tradeDiv m-auto text-center">
                    <img src="assets/contact-us.png" usemap="#Map">
                    <a class="nav-link" routerLink="/contact-us" >
                    <map name="Map">
                      <area shape="rect" coords="78,101,190,140" href="#">
                    </map>
                </a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section id="tradeId" class="tradeDesk">
    <div class="container trade">
        <div class="row">
            <div class="col-sm-12">
              <div class="tradeDiv">
                 <div class="mediaDiv">
                    <div class="media">
                        <img src="assets/faq.svg" class="mr-3" alt="...">
                        <div class="media-body">
                          <h5 class="mt-0">You are a B2B<br> Buyer or Seller?</h5>
                          <p>Make your first trade</p>
                          <a class="joinUs" routerLink="/contact-us">Contact Us</a>
                        </div>
                      </div>
                 </div>
               </div>
            </div>
        </div>
    </div>
</section>




