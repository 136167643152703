import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(private _http: HttpClient) { }

  partnerWithUs(data) {
    return this._http.post(environment.api_url + 'partnerWithUs', data);
  }

  contactUs(data) {
    return this._http.post(environment.api_url + 'contactUs', data);
  }

  getInterestedIn(data) {
    return this._http.post(environment.api_url + 'interestedIn', data);
  }
  
  addEmail(data) {
    console.log('environment.api_url',environment.api_url)
    return this._http.post(environment.api_url + 'addEmail', data);
  }

  getAllWebBanners(data) {
    return this._http.post(environment.api_url + 'getAllWebBanners', data);
  }

  getAllTypesOfCampaigns(data: any){
    console.log('data',data)
    return this._http.post(environment.api_url+'getAllTypesOfCampaignsWeb',data);
  }

  getAllUseCaseCategoriesWeb(){
    return this._http.post(environment.api_url+'getAllUseCaseCategoriesWeb',{});
  }

  getUseCaseByIdWeb(data:any){
    return this._http.post(environment.api_url+'getUseCaseByIdWeb',data);
  }

  getCaseStudyByIdWeb(data:any){
    return this._http.post(environment.api_url+'getCaseStudyByIdWeb',data);
  }

  getUseCaseByCampaignIdWeb(data:any){
    return this._http.post(environment.api_url+'getUseCaseByCampaignIdWeb',data);
  }

  getOneUseCaseWeb(){
    return this._http.post(environment.api_url+'getOneUseCaseWeb',{});
  }

  getHomePageUseCasesWeb(){
    return this._http.post(environment.api_url+'getHomePageUseCasesWeb',{});
  }

  getAllCaseStudiesWeb(){
    return this._http.post(environment.api_url+'getAllCaseStudiesWeb',{});
  }

}
