<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top partnerOuter">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-12 col-sm-12 col-12 py-5">
                <div class="banner_content">
                    <h1 class="title_text">
                        {{caseStudy[0]['title']}}
                    </h1>
                    <p> {{caseStudy[0]['titleDescription']}} </p>

                    <div class="tags">
                        <h5>Tags</h5>
                        <p>{{caseStudy[0]['tags']}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Description-->
<section class="description">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center" data-aos="fade-up" data-aos-duration="500">Description</h1>
            </div>
        </div>
        <div class="row">
            <div class="offset-xl-2 offset-lg-2 offset-md-2 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center">
                        <div class="rectangle pinks" data-aos="fade-up" data-aos-duration="1000">
                            <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+caseStudy[0]['descriptionLogo'][0]"
                                alt="" class="imgCase">
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                        <p data-aos="fade-up" data-aos-duration="1500">{{caseStudy[0]['description']}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="rewards">
                    <ul>
                        <li *ngFor="let client of caseStudy[0]['clients']">
                            <div class="brandName">
                                {{client?.title}}
                            </div>
                            <div class="clientName">
                                {{client?.description}}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Introduction-->
<section>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 data-aos="fade-up" data-aos-duration="1000">Introduction</h1>
                <p class="py-3 px-5" data-aos="fade-up" data-aos-duration="1800">
                    {{caseStudy[0]['introductionDescription']}}</p>
            </div>
        </div>
    </div>
</section>


<!--Challenge-->
<section class="description">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center" data-aos="fade-up" data-aos-duration="500">Challenge</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 text-center">
                <div class="rectangle pinks" data-aos="fade-up" data-aos-duration="1000">
                    <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+caseStudy[0]['challengeLogo'][0]"
                        alt="" class="w-100">
                </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
                <p class="pt-5" data-aos="fade-up" data-aos-duration="1500">{{caseStudy[0]['challengeDescription']}}
                </p>
            </div>
        </div>
    </div>
</section>

<!--Solution-->
<section class="allservices">
    <div class="container ">
        <div class="row mt-5">
            <div class="col-sm-12" data-aos="fade-up" data-aos-duration="1000">
                <div class="text-center">
                    <h1>Solution</h1>
                </div>
            </div>
        </div>
        <div class="row mt-5 justify-content-center" *ngFor="let solution of caseStudy[0]['solutions']; let i=index">
            <div class="col-sm-12">
                <div class="row featurette d-flex">
                    <div class="col-md-6 solutionText  first" [ngClass]="{ 'order-md-2' : i%2 == 0 }">
                        <h3  [ngClass]="{ 'rightHead' : i%2 == 1 }" data-aos="fade-up" data-aos-duration="1300">{{solution?.solutionTitle}}</h3>
                        <ul [ngStyle]="{'text-align': i%2==0?'left':'right'   }">
                             
                            <li data-aos="fade-up" data-aos-duration="1600">
                                {{solution?.solutionDescription}}
                            </li>
                        </ul>
                    </div>
                    <div  class="col-md-6  text-center manageCol last" [ngClass]="{ 'order-md-1' : i%2 == 0 }" data-aos="fade-up" data-aos-duration="1500">
                        <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+solution ['solutionsLogo']"
                            alt="WhatsApp Chatbot">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Impact-->
<section class="impact">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center mb-0" data-aos="fade-up" data-aos-duration="500">Impact</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="rewards">
                    <ul>
                        <li *ngFor="let impact of caseStudy[0]['impact']">
                            <div class="brandName">
                                <!-- {{participation + "M"}} -->
                                {{impact?.count}}+
                            </div>
                            <div class="clientName">
                                {{impact?.title}}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-ui-loader> </ngx-ui-loader>