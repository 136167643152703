<section class="newsletterSub">
    <div class="container">
        <div class="row">
            <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-3 col-md-6 offset-sm-3 col-sm-6 col-12">
                <h3>Subscribe to our newsletter for more refreshing ideas on user engagement.</h3>
                <form [formGroup]="emailUpdateForm" (ngSubmit)="onSubmit()">
                    <div class="row g-3 mb-3">
                        <div class="col-sm-12 mb-5 ">
                            <input type="text" class="form-control" placeholder="Email" formControlName="email">
                            <span *ngIf="successMsg" style="color:green">{{message}}</span>
                            <div *ngIf="(emailUpdateFormSubmitted && f.email.errors) || (f.email.touched && f.email.errors)"
                                class="text-danger">
                                <div *ngIf="f.email.errors.required">Email is required
                                </div>
                                <div *ngIf="f.email.errors.email">Email is Invalid
                                </div>
                            </div>
                        </div>
                        <div class="col-sm mb-5">
                            <button type="submit" class="btn btn-primary"
                                [disabled]="emailUpdateFormDisableSubmit">Subscribe</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<footer class="footer-bootom">
    <div class="main-footer widgets-dark typo-light">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 firstOrder ">
                    <div>
                        <!-- <div class="footer-logo">
                            <a href="index.html"><img src="assets/campaign_bird.png" alt="Campaign Bird"></a>
                        </div> -->
                        <h5 class="widget-title" style="font-weight: 700;">Birdvision Consulting Pvt. Ltd.<span></span>
                        </h5>

                        <div class="addr">
                            <h6>Address</h6>
                            Office: 925/5, 1st Floor<br>
                            Muzumdar Apartments<br>
                            Deendayal Hospital Lane<br>
                            F.C. Road, Pune - 411004<br>
                            Maharashtra, India

                        </div>
                        <div class="mailID">
                            <h6>Email</h6>
                            <a href="mailto:sales@birdvision.in">sales@birdvision.in</a>
                        </div>
                        <div class="phNumber">
                            <h6>Phone</h6>
                            +91 7745096540
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 middleOrder">
                    <div>
                        <h5 style="font-weight: 700;">Company</h5>
                        <ul class="thumbnail-widget">
                            <!-- <li>
                                <div class="thumb-content"><a routerLink="/about-us">About Us
                                    </a></div>
                            </li> -->
                            <li>
                                <div class="thumb-content"><a routerLink="/partners">Partner Program</a></div>
                            </li>
                            <li>
                                <div class="thumb-content"><a routerLink="/contact-us">Contact Us</a></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 middleOrder">
                    <div>
                        <h5 style="font-weight: 700;">Our solutions</h5>
                        <ul class="thumbnail-widget">
                            <li>
                                <div class="thumb-content">
                                    <a routerLink="/WhatsApp-commerce">WhatsApp Commerce</a>
                                </div>
                            </li>
                            <li>
                                <div class="thumb-content">
                                    <a href="https://retailbird.birdvisiontech.com/trade-scheme/" target="_blank">Trade
                                        Schemes</a>
                                </div>
                            </li>
                            <li>
                                <div class="thumb-content">
                                    <a routerLink="/Home">CampaignBird</a>
                                </div>
                            </li>
                            <li>
                                <div class="thumb-content">
                                    <a href="https://retailbird.birdvisiontech.com" target="_blank">RetailBird</a>
                                </div>
                            </li>
                            <li>
                                <div class="thumb-content"><a routerLink="/supply-chain-visibility">Supply Chain
                                        Visibility</a></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mainOrder">
                    <div class="desktopMedia">
                        <h5 style="font-weight: 700;">Follow Us</h5>
                        <ul class="social-footer ">
                            <li><a href="https://www.linkedin.com/company/birdvision-consulting-pvt-ltd-"
                                    target="_blank" title="LinkedIn">LinkedIn</a></li>
                            <li><a href="https://m.facebook.com/BirdVision-Consulting-Private-Limited-231667354236559/"
                                    target="_blank" title="Facebook">Facebook</a></li>
                            <li><a href="https://twitter.com/Birdvision?s=15" target="_blank"
                                    title="Twitter">Twitter</a>
                            </li>
                            <!-- <li><a href="" target="_blank" title="You Tube">You Tube</a>
                            </li> -->
                            <li><a href="https://www.instagram.com/campaign.bird/" target="_blank"
                                    title="Instagram">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mobileMedia lastOrder">
                    <div class="">
                        <h5 style="font-weight: 700;">Follow Us</h5>
                        <ul class="social-footer ">
                            <li><a href="https://www.linkedin.com/company/birdvision-consulting-pvt-ltd-"
                                    target="_blank" title="LinkedIn">LinkedIn</a></li>
                            <li><a href="https://m.facebook.com/BirdVision-Consulting-Private-Limited-231667354236559/"
                                    target="_blank" title="Facebook">Facebook</a></li>
                            <li><a href="https://twitter.com/Birdvision?s=15" target="_blank"
                                    title="Twitter">Twitter</a>
                            </li>
                            <!-- <li><a href="" target="_blank" title="You Tube">You Tube</a>
                            </li> -->
                            <li><a href="https://www.instagram.com/campaign.bird/" target="_blank"
                                    title="Instagram">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div>
        <div class="container footer-copyright">
            <div class="row">
                <div class="col-sm-12">
                    <div class="links">
                        <ul>
                            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-12 text-center">
                    <span> © CampaignBird. {{currentYear}}</span>
                </div>
            </div>
        </div>
    </div>
</footer>

<!--====== FOOTER PART ENDS ======-->