import { Component, OnInit } from "@angular/core";
declare var jQuery: any;
declare var $: any;
@Component({
  selector: "app-whatsappcommerce",
  templateUrl: "./whatsappcommerce.component.html",
  styleUrls: ["./whatsappcommerce.component.css"],
})
export class WhatsappcommerceComponent implements OnInit {
  public section: string;

  constructor() {}

  ngOnInit(): void {
    // document.getElementById("scrolledHere").scrollIntoView();
    // let temp = document.getElementById("eComPortalBtn")
    // $(temp).addClass("active");
    (function ($) {
      $(document).ready(function () {
        $(".logo-carousel").slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: false,
          pauseOnHover: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 520,
              settings: {
                slidesToShow: 2,
              },
            },
          ],
        });
      });
    })(jQuery);
  }

  goTo(id: string) {
    if (id == "eComPortalBtn") {
      document.getElementById("scrolledHereToECom").scrollIntoView();

      var element = document.getElementById("WhatsAppComBtn");
      element.classList.remove("active");
      let temp = document.getElementById("eComPortalBtn");
      $(temp).addClass("active");
    }

    if(id=="WhatsAppComBtn"){
      document.getElementById("scrolledHereToWhatsAppCommerce").scrollIntoView();

      var element = document.getElementById("eComPortalBtn");
      element.classList.remove("active");
      let temp = document.getElementById("WhatsAppComBtn")
      $(temp).addClass("active");
    }
  }

  navigateToSection(event) {
    console.log(event);
  }
}
