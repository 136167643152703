import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WebsiteService } from '../../website.service';
import { EncrDecrService } from 'src/app/shared/EncrDecrService';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  emailUpdateForm: FormGroup;
  disableSubmit: boolean;
  successMsg: boolean = false;
  emailUpdateFormDisableSubmit: boolean = false;
  emailUpdateFormSubmitted: boolean = false;
  message: string;
  currentYear: any = (new Date()).getFullYear();
  constructor(private fb: FormBuilder,
    private WebsiteService: WebsiteService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _EncrDecr: EncrDecrService,
    ) { }

  ngOnInit(): void {
    this.emailUpdateForm = this.fb.group({
      email: ['', [Validators.email,Validators.required]]
    });
  }
  get f() { return this.emailUpdateForm.controls; }

  onSubmit() {
    // if (this.emailUpdateForm.invalid) {
    //   this.disableSubmit = false;
    //   return;
    // }
    this.emailUpdateFormSubmitted = true;
    this.emailUpdateFormDisableSubmit = true;
    if (this.emailUpdateForm.invalid) {
      this.emailUpdateFormDisableSubmit = false;
      return;
    }
    let data = {
      "email": this._EncrDecr.set(environment.keyforencdec, this.emailUpdateForm.value.email),
    };
    console.log('data...',data)
    this.WebsiteService.addEmail(data).subscribe((data) => {
      if (data['status']) {
        this.message = "Subscribe Successfully";
        this.successMsg = true;
        setTimeout(() => {
          this.successMsg = false;
          this.emailUpdateForm.reset();
          this.emailUpdateFormDisableSubmit = false;
          this._router.navigate(['/contact-us']);
        }, 2000);
      } else {
        this.successMsg = true;
        this.message = "Not Subscribe";
      }
    });

  }

}
