<!-- <app-header></app-header>
  <app-home></app-home>
  <app-about-us></app-about-us>
  <app-services></app-services>
  <app-partners></app-partners>
  <app-contact-us></app-contact-us>
  <app-footer></app-footer> -->
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<!--====== BACK TOP TOP PART START ======-->

<a href="#" class="back-to-top"><i class="lni lni-chevron-up"></i></a>

<!--====== BACK TOP TOP PART ENDS ======-->