import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})

export class FaqComponent implements OnInit {

  
  constructor() { 
    $(document).ready(function(){
      // Add minus icon for collapse element which is open by default
      // $(".collapse.show").each(function(){
      //   $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
      // });
      
      // Toggle plus minus icon on show hide of collapse element
      $(".card .collapse").on('show.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
      }).on('hide.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
      });
  });
  }

  ngOnInit(): void {
    //$('.collapse').collapse()
  }

}
