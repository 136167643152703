<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top partnerOuter">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-5 col-sm-12 col-12 first">
                <div class="banner_content">
                    <h1 class="title_text">
                        Contact Us
                    </h1>
                    <!-- <p>You can partner with CampaignBird to expand revenue opportunities for your business by delivering more value to your customers.</p> -->
                </div>
            </div>
            <div class="col-lg-7  col-sm-12 col-12 last">
                <div class="outerBannerimg">
                    <img src="assets/contact-us.png" class="" alt="Contact Us Banner">
                </div>
            </div>

        </div>
    </div>
</section>
<section id="partners" class="partners-bg-color">
    <!-- <div class="text-center">
        <h1  data-aos="fade-up">Partners</h1>
    </div> -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 outD" #target>
                <div class="leftImg text-center">
                    <div>
                        <img src="assets/schedule.png" alt="">
                    </div>
                    <h5 class="mt-5">Schedule Meeting</h5>
                    <a href="https://calendly.com/aishwarya-24" class="btn btn-success" target="_blank">Schedule</a>
                </div>

            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                <div class="outerForm">
                    <h4 class="text-center">Get in Touch</h4>
                    <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
                        <div class="mb-4">
                            <label for="inputfullName" class="form-label">Name <span>*</span></label>
                            <input type="text" class="form-control" id="inputfullName" placeholder="Enter full name"
                                formControlName="fullName">
                            <div *ngIf="(contactUsFormSubmitted && f.fullName.errors) || (f.fullName.touched && f.fullName.errors)"
                                class="text-danger">
                                <div *ngIf="f.fullName.errors.required">Full Name is required
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputEmail" class="form-label">Business Email <span>*</span></label>
                            <input type="text" class="form-control" id="inputEmail" placeholder="Enter business email"
                                formControlName="email">
                            <div *ngIf="(contactUsFormSubmitted && f.email.errors) || (f.email.touched && f.email.errors)"
                                class="text-danger">
                                <div *ngIf="f.email.errors.required">Business E-mail is required
                                </div>
                                <div *ngIf="f.email.errors.email">Business E-mail is Invalid
                                </div>
                            </div>
                            <!-- <div *ngIf="(contactUsFormSubmitted && f.email.errors) || (f.email.touched && f.email.errors)"
                                class="text-danger">
                                <div *ngIf="f.email.errors.required">Business E-mail is required
                                </div>
                            </div> -->
                        </div>
                        <div class="mb-4">
                            <label for="inputCountry" class="form-label">Country <span>*</span></label>
                            <input type="text" class="form-control" id="inputCountry" placeholder="Enter country"
                                formControlName="country">
                            <div *ngIf="(contactUsFormSubmitted && f.country.errors) || (f.country.touched && f.country.errors)"
                                class="text-danger">
                                <div *ngIf="f.country.errors.required">Country is required
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputPhone" class="form-label">Phone</label>
                            <div class="row">
                                <div class="col-3">
                                    <select class="form-select form-control" id="inputState"
                                        formControlName="stateCode">
                                        <option value="">Select</option>
                                        <option value="+91">+91</option>
                                        <option value="+43">+43</option>
                                    </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control" id="inputNumber"
                                        placeholder="Enter mobile or phone" formControlName="contactNumber">
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputInterest" class="form-label">Interested in campaign type
                                <span>*</span></label>
                            <select class="form-select form-control" id="inputInterest" formControlName="interestedIn">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let intrests of interestedIn" value="{{intrests.heading}}">
                                    {{intrests.heading}}</option>
                            </select>
                            <div *ngIf="(contactUsFormSubmitted && f.interestedIn.errors) || (f.interestedIn.touched && f.interestedIn.errors)"
                                class="text-danger">
                                <div *ngIf="f.interestedIn.errors.required">Interested in campagin type is required
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputDesig" class="form-label">Job Title</label>
                            <input type="text" class="form-control" id="inputDesig"
                                placeholder="Enter job title or designation" formControlName="designation">
                        </div>
                        <div class="mb-4">
                            <label for="inputDesc" class="form-label">Tell us more about your business requirment
                            </label>
                            <textarea class="form-control" id="inputDesc" rows="3" placeholder="Your message"
                                formControlName="description"></textarea>
                        </div>
                        <div class="mb-4 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                formControlName="confirmation">
                            <label class="form-check-label pri" for="exampleCheck1">I confirm that I wish to be
                                contacted by
                                you regarding your products & services and agree to storing and processing of data as
                                mentioned in CampaignBird's <a routerLink="/privacy-policy">Privacy Statement</a>.
                            </label>
                            <div *ngIf="(contactUsFormSubmitted && f.confirmation.errors) || (f.confirmation.touched && f.confirmation.errors)"
                                class="text-danger">
                                <div *ngIf="f.confirmation.errors.required">Confirmation is required
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn submitBtn"
                                [disabled]="contactUsFormDisableSubmit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</section>
<ngx-ui-loader> </ngx-ui-loader>