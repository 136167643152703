<section class="mainHead text-center">
  <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">

      <div class="carousel-item active">
        <ul>
          <li>Create an end-to-end shopping experience on WhatsApp</li>
          <li><a routerLink="/WhatsApp-commerce">Know More</a></li>
        </ul>
        <div class="closeBtn">
          <img src="assets/close.png" (click)="hideTop()" alt="Close Icon">
        </div>
      </div>
      <div class="carousel-item ">
        <ul>
          <li>Drive reach and engagement for your IPL campaigns </li>
          <li><a routerLink="/ipl">Know More</a></li>
        </ul>
        <div class="closeBtn">
          <img src="assets/close.png" (click)="hideTop()" alt="Close Icon">
        </div>
      </div>
    </div>
  </div>

</section>
<nav class="navbar navbar-expand-lg navbar-light " [class.headerClass]="headerBoolean" id="navTop">
  <div class="container-fluid">

    <a class="navbar-brand" [class.Brand]="headerBrand" routerLink="/Home" id="logohome" (click)="clickMe(0)">
      <img [src]="imagePath" alt="CampaignBird" title="CampaignBird">
    </a>

    <button class="navbar-toggler navbar-toggler-right collapsed checkall" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation" (click)="headerFun()">
      <span class="my-1 mx-2 close" (click)="chengeLogoColorToWhite()">X</span>
      <span class="navbar-toggler-icon" (click)="chengeLogoColorToColored()"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto" id="linkId">
        <li class="nav-item">
          <!-- {{this.headerColors}} -->
          <a class="nav-link actives" routerLink="/Home" id="home" (click)="clickMe('home')"
            [ngStyle]="{'color':'#001685' }">Home <span class="sr-only">(current)</span></a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link"   routerLink="/about-us" id="aboutus"   (click)="clickMe(2)" [ngStyle]="{'color': headerColors=='white' ? headerColors : headerColors}">Campaign</a>
        </li> -->

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownCampaigns" role="button" data-toggle="dropdown"
            aria-expanded="false" (click)="clickMe('campaigns')" [ngStyle]="{'color':'#001685' }">
            Campaigns
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownCampaigns">

            <a class="dropdown-item use" routerLink="/use-case">
              <div class="linkOuter">
                <span class="dropHead">Use Cases</span>
                <!-- <span class="submenu">Submenu information here - if needed.</span> -->
              </div>
            </a>
            <!-- <a class="dropdown-item event"  routerLink="/event-calendar">
              <div class="linkOuter">
                <span class="dropHead">Event Calendar</span>
              </div>
              </a> -->
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="/case-studies" id="service" (click)="clickMe('caseStudies')"
            [ngStyle]="{'color':'#001685' }">Case Studies</a>
        </li>

        <li class="nav-item dropdown">
          <a (click)="clickMe('whatsappCommerce')" class="nav-link dropdown-toggle" href="#" id="commerce" role="button"
            data-toggle="dropdown" arial-expanded="false" [ngStyle]="{'color':'#001685' }">
            Our Solutions
          </a>
          <div class="dropdown-menu " aria-labelledby="navbarDropdownContactUs">
            <a class="dropdown-item web" routerLink="/WhatsApp-commerce">
              <div class="linkOuter">
                <span class="dropHead">WhatsApp Commerce</span>
              </div>
            </a>
            <a class="dropdown-item web" href="https://retailbird.birdvisiontech.com/trade-scheme/" target="_blank">
              <div class="linkOuter">
                <span class="dropHead">Trade Schemes</span>
              </div>
            </a>
            <a class="dropdown-item web" routerLink="/Home">
              <div class="linkOuter">
                <span class="dropHead">CampaignBird</span>
              </div>
            </a>
            <a class="dropdown-item web" href="https://retailbird.birdvisiontech.com" target="_blank">
              <div class="linkOuter">
                <span class="dropHead">RetailBird</span>
              </div>
            </a>
            <a class="dropdown-item web" routerLink="/supply-chain-visibility">
              <div class="linkOuter">
                <span class="dropHead">Supply Chain Visibility</span>
              </div>
            </a>
            <a class="dropdown-item web" routerLink="/csr">
              <div class="linkOuter">
                <span class="dropHead">CSR-ESG Initiatives</span>
              </div>
            </a>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a (click)="clickMe('contactUs')" class="nav-link dropdown-toggle" href="#" id="navbarDropdownContactUs"
            role="button" data-toggle="dropdown" aria-expanded="false" [ngStyle]="{'color':'#001685' }">
            Contact Us
          </a>
          <div class="dropdown-menu " aria-labelledby="navbarDropdownContactUs">
            <a class="dropdown-item contact" routerLink="/contact-us">
              <div class="linkOuter">
                <span class="dropHead">Contact Us</span>
                <!-- <span class="submenu">Submenu information here - if needed.</span> -->
              </div>
            </a>
            <a class="dropdown-item sche" href="https://calendly.com/aishwarya-24" target="_blank">
              <div class="linkOuter">
                <span class="dropHead">Schedule a Meeting</span>
                <!-- <span class="submenu">Submenu information here - if needed.</span> -->
              </div>
            </a>
          </div>
        </li>

        <li class="nav-item">
          <!--  -->
          <a class="nav-link" routerLink="/partners" id="partners" (click)="clickMe('partnerWithUs')"
            [ngStyle]="{'color':'#001685' }">Partner With Us</a>
        </li>
        <!-- <li class="nav-item">
          <a  class="nav-link" routerLink="/WhatsApp-commerce"   [ngStyle]="{'color':'#001685' }">WhatsApp Commerce</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link"   routerLink="/contact-us" id="contactus" (click)="clickMe(5)" [ngStyle]="{'color': headerColors=='white' ? headerColors : headerColors}">Contact Us</a>
        </li> -->

      </ul>
    </div>
  </div>
</nav>