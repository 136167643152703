<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top partnerOuter">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-5 col-sm-12 col-12 first">
                <div class="banner_content">
                    <h1 class="title_text">
                        Use Cases
                    </h1>
                </div>
            </div>
            <div class="col-lg-7  col-sm-12 col-12 last">
                <div class="outerBannerimg">
                    <img src="assets/use-case.png" class="" alt="Use Cases">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="case"  id="scrolledHere">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 first">
                <div class="row" >
                    <div class="col-sm-12" >
                        <h3>{{ useCase.length>0 ? useCase[0]['responseData']?.subCategoriesInfo[0]?.name : ''}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Objective</h5>
                        <p>{{useCase.length>0 ? useCase[0]['responseData']?.objective : ''}}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Types of campaigns</h5>
                        <ul>
                            <li *ngFor="let campaign of typeOfCampaignsInfo; let i=index">{{i+1}}. {{campaign?.title}}
                            </li>
                            <!-- <li>2. Brand awareness and recall - Share selfie of “moments”/ physical activity image/video.</li> -->
                        </ul>

                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>User Journey</h5>
                    </div>
                    <div class="col-sm-12">
                        <div class="row mb-5" *ngIf="useCase.length>0">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                                *ngFor="let journey of useCase[0]['responseData']?.userJourney; let i=index">
                                <div class="box">
                                    <div class="topNumber">
                                        <div class="step">STEP</div>
                                        <div class="num">{{i+1}}</div>
                                    </div>
                                    <p>{{journey?.stepsDesc}}
                                    </p>
                                </div>
                            </div>
                            <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="box">
                                    <div class="topNumber">
                                        <div class="step">STEP</div>
                                        <div class="num">02</div>
                                    </div>
                                    <p>Choose a preferred language.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="box">
                                    <div class="topNumber">
                                        <div class="step">STEP</div>
                                        <div class="num">03</div>
                                    </div>
                                    <p>Share photos and/or videos.
                                    </p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="box">
                                    <div class="topNumber">
                                        <div class="step">STEP</div>
                                        <div class="num">04</div>
                                    </div>
                                    <p>Share information like pincode, name etc.</p>
                                </div>
                            </div> -->
                            <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="box">
                                    <div class="topNumber">
                                        <div class="step">STEP</div>
                                        <div class="num">05</div>
                                    </div>
                                    <p>Share information like pin code, name (profile completion).</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h5>Successfully implemented on</h5>

                    </div>
                </div>

                <div class="col-12 mt-4">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="trendingproductsConfig">
                        <div ngxSlickItem class="slide" *ngFor="let logos of logssss">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="rightSide bg-white">
                                        <div class="row">
                                            <div class="col-12 text-center mt-3">
                                                <div class="thumbnail">
                                                    <img class="card-img-top"
                                                    [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+logos" alt=""
                                                        >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>            
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 last">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="categories">
                            Categories
                        </div>

                        <div id="accordion" class="accordion">
                            <div class="card mb-0" *ngFor='let category of useCaseCategories; let i = index'>
                                <div class="card-header collapsed" data-toggle="collapse"
                                    attr.href="#collapseOne_{{i}}">
                                    <a class="card-title" href="javaScript:void(0)">
                                        {{category.name}}
                                    </a>
                                </div>
                                <div id="collapseOne_{{i}}" class="card-body collapse" data-parent="#accordion">
                                    <a *ngFor='let subCategory of category.subCategoriesInfo; let i = index'
                                        [routerLink]="['/usecase', subCategory?._id]"
                                        (click)="getuseCaseData(subCategory?._id)">{{subCategory.name}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <a routerLink="/contact-us" class="btn contactBtn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>