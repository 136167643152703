<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-bottom">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 col-sm-12 col-12 first">
                <div class="banner_content">
                    <h1 class="title_text">
                        Now automate engagement with key stakeholders, make initiatives more trackable and measurable,
                        and improve ROI on your CSR spends.
                    </h1>

                </div>
            </div>
            <div class="col-lg-6  col-sm-12 col-12 last">
                <div class="outerBannerimg">
                    <img src="assets/csr/csr.svg" class="" alt="CSR">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-center">
    <h1 class="csrHead">CSR Challenges</h1>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h3>As more companies are looking to create visible and measurable impact for their CSR initiatives,
                    they face real challenges like
                </h3>
            </div>
        </div>
    </div>
</section>


<!--Technologies Desktop-->
<section class="outerTrade techDesktop">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                data-aos-duration="500">
                <div class="outerUltimate">
                    <img src="assets/csr/1.svg" alt="Low Engagement">
                    <h4>Low Engagement</h4>
                    <p>Low engagement with key stakeholders over time leads to a drop in initial interest leading to
                        many people dropping out of the initiative.</p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-sm-6 col-12 text-center outerCommonHeight"
                data-aos="fade-up" data-aos-duration="800">
                <div class="outerUltimate">
                    <img src="assets/csr/2.svg" alt="Scaling ">
                    <h4>Scaling</h4>
                    <p>Scaling initiatives in the absence of the right technology doesn’t work. Whether it's horizontal
                        and vertical server scaling or web application scaling, we've got you covered.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                data-aos-duration="1000">
                <div class="outerUltimate">
                    <img src="assets/csr/3.svg" alt="Access to important information ">
                    <h4>Access to important information </h4>
                    <p>Access to important information is dependent on physical/digital touchpoints that may not be
                        easily accessible to beneficiaries leading to lower participation.</p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                data-aos-duration="1000">
                <div class="outerUltimate">
                    <img src="assets/csr/4.svg" alt="Measuring Impact
                    ">
                    <h4>Measuring Impact</h4>
                    <p>The lack of KPIs like reach, engagement, and feedback makes it hard to do a proper impact
                        assessment.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Technologies Mobile-->
<section class="outerTrade techMobile">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div id="carouseltechMobile" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators usecaseCarousel">
                        <li data-target="#carouseltechMobile" data-slide-to="0" class="active"></li>
                        <li data-target="#carouseltechMobile" data-slide-to="1"></li>
                        <li data-target="#carouseltechMobile" data-slide-to="2"></li>
                        <li data-target="#carouseltechMobile" data-slide-to="3"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up"
                                    data-aos-duration="500">
                                    <div class="outerUltimate">
                                        <img src="assets/csr/1.svg" alt="Low Engagement">
                                        <h4>Low Engagement</h4>
                                        <p>Low engagement with key stakeholders over time leads to a drop in initial
                                            interest leading to
                                            many people dropping out of the initiative.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up"
                                    data-aos-duration="800">
                                    <div class="outerUltimate">
                                        <img src="assets/csr/2.svg" alt="Scaling ">
                                        <h4>Scaling</h4>
                                        <p>Scaling initiatives in the absence of the right technology doesn’t work.
                                            Whether it's horizontal
                                            and vertical server scaling or web application scaling, we've got you
                                            covered.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up"
                                    data-aos-duration="1000">
                                    <div class="outerUltimate">
                                        <img src="assets/csr/3.svg" alt="Access to important information ">
                                        <h4>Access to important information </h4>
                                        <p>Access to important information is dependent on physical/digital touchpoints
                                            that may not be
                                            easily accessible to beneficiaries leading to lower participation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up"
                                    data-aos-duration="500">
                                    <div class="outerUltimate">
                                        <img src="assets/csr/4.svg" alt="Measuring Impact
                                        ">
                                        <h4>Measuring Impact</h4>
                                        <p>The lack of KPIs like reach, engagement, and feedback makes it hard to do a
                                            proper impact
                                            assessment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="text-center">
    <h1 class="csrHead">CampaignBird Solution</h1>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-7 order-md-1 manageCol ">
                <h3 class="headH">CampaignBird can help you provide more reach and engagement with your beneficiaries,
                    generate data for easy tracking and measurement, and make your initiatives selfsustainable and
                    scalable.
                </h3>
            </div>
            <div class="col-md-5 order-md-2 manageCol text-center">
                <img src="assets/csr/Campaign-Solution.svg" class="adv" alt="">
            </div>
        </div>
    </div>
</section>
<section class="text-center">
    <h1 class="csrHead">What makes CampaignBird a must have tool for your CSR<br> teams?</h1>
</section>

<section class="case" id="scrolledHere">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 last mb-5">
                <img src="assets/csr/campaign-team.svg" class="adv" alt="">
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 first">
                <div class="row">
                    <div class="col-sm-6">
                        <h5>Multiple onboarding mechanisms</h5>
                        <p>Whatsapp SMS, Missed Call
                        </p>
                    </div>
                    <div class="col-sm-6">
                        <h5>Easier to collect and share information</h5>
                        <p>Using WhatsApp Chatbot</p>
                    </div>
                    <div class="col-sm-6">
                        <h5>Language localization </h5>
                        <p>English and 10 major Indian languages are supported.</p>
                    </div>
                    <div class="col-sm-6">
                        <h5>Dashboards </h5>
                        <p>Reports and visualisations with access management for complete
                            visibility of program performance.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="outcome">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1>Whether you have an idea or have already implemented a CSR initiative, we can help in improving the
                    outcomes.
                </h1>
            </div>
        </div>
    </div>
</section>

<section class="csrPr">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1> Let's discuss and help your CSR programs set new benchmarks in the social impact space.
                </h1>
                <a class="contactUs" routerLink="/contact-us">Contact us</a>
            </div>
        </div>
    </div>
</section>