import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../website.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-case-study-data',
  templateUrl: './case-study-data.component.html',
  styleUrls: ['./case-study-data.component.css']
})
export class CaseStudyDataComponent implements OnInit {
  scrWidth: number;
  caseStudyCount: number;
  caseStudies: any[];
  error: any;
  sliderImages: any = [];

  constructor(private _websiteService: WebsiteService, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.getAllCaseStudiesWeb();
  }

  getAllCaseStudiesWeb() {
    this.ngxLoader.start();
    this._websiteService.getAllCaseStudiesWeb().subscribe(
      (res) => {
        if (res['status']) {
          this.scrWidth = window.innerWidth;

          if (this.scrWidth > 1300) {
            this.caseStudyCount = 4;
          } else if (this.scrWidth < 1300 && this.scrWidth > 1025) {
            this.caseStudyCount = 4;
          } else if (this.scrWidth <= 991) {
            this.caseStudyCount = 1;
          }

          console.log('this.caseStudyCount', this.caseStudyCount)
          this.caseStudies = this.chunksOfCaseStudy(res, this.caseStudyCount);
          console.log('this.caseStudies', this.caseStudies);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.error = err;
      }
    );
  }

  chunksOfCaseStudy(array, size) {
    console.log('size', size)
    let R = [];
    for (let i = 0, len = array['data'].length; i < len; i += size) {
      R.push(array['data'].slice(i, i + size));
    }
    console.log('rrrrrr', R)
    for (let i = 0; i < R.length; i++) {
      this.sliderImages.push(i)
    }
    return R;
  }

}
