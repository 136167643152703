import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router, NavigationEnd, Event } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { EncrDecrService } from '../shared/EncrDecrService';
import { WebsiteService } from '../website.service';

declare var jquery: any;
declare var $: any

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {
  contactUsForm!: FormGroup;
  interestedIn: any;
  page: number = 1;
  contactUsFormSubmitted: boolean = false;
  contactUsFormDisableSubmit: boolean = false;
  currentUrl: string;
  constructor(private _formBuilder: FormBuilder,
    private _websiteService: WebsiteService,
    private _router: Router,
    private _EncrDecr: EncrDecrService, private ngxLoader: NgxUiLoaderService) {

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    if (this.currentUrl == '/schedule-meeting') {
      console.log('here')
      window.scrollTo(0, 550);
    }
    this.contactUsForm = this._formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      country: ['', Validators.required],
      stateCode: ['+91', ''],
      contactNumber: ['', ''],
      interestedIn: ['', Validators.required],
      designation: ['', ''],
      description: ['', ''],
      confirmation: ['', Validators.required],
    });

    const data = {
      page: this.page,
      limit: 100,
      moduleName: 'contactUs'
    }
    this._websiteService.getInterestedIn(data).subscribe((data: { [x: string]: any; }) => {
      if (data['status'] == true) {
        this.interestedIn = data['data'][0]['data'];
      }
    });

  }

  get f() { return this.contactUsForm.controls; }

  onSubmit() {
    console.log('this.contactUsForm.value.stateCode', this.contactUsForm.value.stateCode)
    this.contactUsFormSubmitted = true;
    this.contactUsFormDisableSubmit = true;
    if (this.contactUsForm.invalid) {
      this.contactUsFormDisableSubmit = false;
      return;
    }
    const payload = {
      fullName: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.fullName),
      email: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.email),
      country: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.country),
      stateCode: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.stateCode),
      contactNumber: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.contactNumber),
      interestedIn: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.interestedIn),
      designation: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.designation),
      description: this._EncrDecr.set(environment.keyforencdec, this.contactUsForm.value.description),
    }
    this.ngxLoader.start();
    this._websiteService.contactUs(payload).subscribe((data: { [x: string]: any; }) => {
      if (data['status'] == true) {
        this.ngxLoader.stop();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'submitted successfully',
        }).then((result) => {
          this.contactUsForm.reset();
          this.contactUsFormSubmitted = false;
          this.contactUsFormDisableSubmit = false;
        });
      } else {
        this.ngxLoader.stop();
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Not submitted successfully',
        });
        this.contactUsFormDisableSubmit = false;
      }
    });

  }

}