import { Component, OnInit } from '@angular/core';

declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-supply-chain-visibility',
  templateUrl: './supply-chain-visibility.component.html',
  styleUrls: ['./supply-chain-visibility.component.css']
})
export class SupplyChainVisibilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    this.stopCarousel();
    (function ($) {
      $(document).ready(function () {
        $('.logo-carousel').slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: false,
          pauseOnHover: false,
          responsive: [{
            breakpoint: 768,
            settings: {
              slidesToShow: 4
            }
          }, {
            breakpoint: 520,
            settings: {
              slidesToShow: 2
            }
          }]
        });
      });
    })(jQuery);
  }
  stopCarousel() {
    $('.carousel').carousel({
      pause: "hover"
    })
  }
}
