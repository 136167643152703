import { Component, OnInit, HostListener } from '@angular/core';
 
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  
})
export class AboutUsComponent implements OnInit {

  citiescount:number = 0;

  customercount:number = 0;

  productscount:number =0;

   citiescountstop = setInterval(()=>{
    this.citiescount = this.citiescount === 55 ? 0 : this.citiescount + 1;
    // console.log(this.citiescount)
    if(this.citiescount === 55)
    {
      clearInterval(this.citiescountstop);
    }
  }, 20)  

  customercountstop= setInterval(()=>{
    this.customercount = this.customercount === 200 ? 0 : this.customercount + 1;
    if(this.customercount == 200)
    {
      clearInterval(this.customercountstop);
    }
  }, 15)

  productscountstop = setInterval(()=>{
    this.productscount = this.productscount === 16000 ? 0 : this.productscount + 50;
    
    if(this.productscount == 16000)
    {
      clearInterval(this.productscountstop);
    }
  }, 20)

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(event) {

    let heightScroll = document.documentElement.scrollTop
    let screenWidth = screen.width
   // let counter = 8000
  //  console.log('Screen Width', screenWidth);
  //   let tempCounter = 0
  //   console.log('heightScroll',heightScroll)
    if(heightScroll >= 1877 && heightScroll <= 1900)
    {
      //console.log('enter...')
          // this.animateValue(5, 56, 50);
          // this.animateValue1(10, 201, 100);
          // this.animateValue2(100, 8001, 100);
          var citiescountstop = setInterval(()=>{
             this.citiescount = this.citiescount === 55 ? 0 : this.citiescount + 1;
            //  console.log(this.citiescount)
             if(this.citiescount === 55)
             {
               clearInterval(citiescountstop);
             }
           }, 20)
     
           var customercountstop= setInterval(()=>{
             this.customercount = this.customercount === 200 ? 0 : this.customercount + 1;
             if(this.customercount == 200)
             {
               clearInterval(customercountstop);
             }
           }, 15)
     
           var productscountstop = setInterval(()=>{
             this.productscount = this.productscount === 16000 ? 0 : this.productscount + 50;
             
             if(this.productscount == 16000)
             {
               clearInterval(productscountstop);
             }
           }, 20)
         
    }
    if(screenWidth == 768)
    {
      if(heightScroll >= 1634 && heightScroll <= 1650)
    {
      // console.log('enter...')
    
      //     this.animateValue(5, 56, 50);
      //     this.animateValue1(10, 201, 100);
      //     this.animateValue2(100, 8001, 100);

      var citiescountstop = setInterval(()=>{
        this.citiescount = this.citiescount === 55 ? 0 : this.citiescount + 1;
        // console.log(this.citiescount)
        if(this.citiescount === 55)
        {
          clearInterval(citiescountstop);
        }
      }, 20)

      var customercountstop= setInterval(()=>{
        this.customercount = this.customercount === 200 ? 0 : this.customercount + 1; 
        if(this.customercount == 200)
        {
          clearInterval(customercountstop);
        }
      }, 15)

      var productscountstop = setInterval(()=>{
        this.productscount = this.productscount === 16000 ? 0 : this.productscount + 50;
        
        if(this.productscount == 16000)
        {
          clearInterval(productscountstop);
        }
      }, 20)
       
    }
    }

    if(screenWidth == 767)
    {
      if(heightScroll >= 3485 && heightScroll <= 3783)
    {
      // console.log('enter...')
    
      //     this.animateValue(5, 56, 50);
      //     this.animateValue1(10, 201, 100);
      //     this.animateValue2(100, 8001, 100);

      var citiescountstop = setInterval(()=>{
        this.citiescount = this.citiescount === 55 ? 0 : this.citiescount + 1;
        // console.log(this.citiescount)
        if(this.citiescount === 55)
        {
          clearInterval(citiescountstop);
        }
      }, 20)

      var customercountstop= setInterval(()=>{
        this.customercount = this.customercount === 200 ? 0 : this.customercount + 1;
        if(this.customercount == 200)
        {
          clearInterval(customercountstop);
        }
      }, 15)

      var productscountstop = setInterval(()=>{
        this.productscount = this.productscount === 16000 ? 0 : this.productscount + 50;
        
        if(this.productscount == 16000)
        {
          clearInterval(productscountstop);
        }
      }, 20)
       
    }
    }
  };


  
  // animateValue(start, end, duration) {
     
  //   let obj = document.getElementById('ct');
  //   console.log('obj',obj)
  //   const step = (timestamp) => {
  //   if (start < end ) {
  //       obj.innerHTML = (start) ;
  //       window.requestAnimationFrame(step);
  //     }
  //     start = start + 5
  //   };
  //   window.requestAnimationFrame(step);
  // }
  // animateValue1(start, end, duration) {
   
  //   let obj1 = document.getElementById('cct');
  //   console.log('obj',obj1)
  //   const step = (timestamp) => {
  //   if (start < end ) {
  //       obj1.innerHTML = (start)
  //       window.requestAnimationFrame(step);
  //     }
  //     start = start + 10
  //   };
  //   window.requestAnimationFrame(step);
  // }
  // animateValue2(start, end, duration) {
  
  //   let obj2 = document.getElementById('ccct');
  //   console.log('obj',obj2)
  //   const step = (timestamp) => {
  //   if (start < end ) {
  //       obj2.innerHTML = (start);
  //       window.requestAnimationFrame(step);
  //     }
  //     start= start+100
  //   };
  //   window.requestAnimationFrame(step);
  // }
}
