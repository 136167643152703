
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';

import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  headerBoolean: boolean = false;
  headerBrand: boolean = false;
  currentRoute: string;
  headerColors: string;
  currentUrl: string;
  imagePath: string;
  hamburgerOpened: boolean = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.activationOfLink(this.currentUrl.split('/')[1]);
        let screenWidth = window.innerWidth;

        if ((this.currentUrl == '/Home' || this.currentUrl == '/') && screenWidth > 991) {
          // console.log('normal screen and home page')
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          document.getElementById('navTop').classList.add('navbar');
          document.getElementById('navTop').classList.remove('fixed-top');
          this.headerColors = 'white'

          this.imagePath = "assets/campaign_bird.png";
        } else if ((this.currentUrl == '/Home' || this.currentUrl == '/') && screenWidth <= 991) {
          // console.log('responsive screen and home page')
          if (this.hamburgerOpened) {
            this.imagePath = "assets/campaign_bird.png";
            (document.querySelector('.navbar-toggler-icon') as HTMLElement).style.backgroundImage = "url('/assets/toggle-icon.png')";
            (document.querySelector('.navbar-toggler') as HTMLElement).style.backgroundColor = "#011784";
            (document.querySelector('.close') as HTMLElement).style.color = "white";
          } else {
            this.imagePath = "assets/campaign_bird.png";
            (document.querySelector('.navbar-toggler-icon') as HTMLElement).style.backgroundImage = "url('/assets/toggle-icon-black.png')";
            (document.querySelector('.navbar-toggler') as HTMLElement).style.backgroundColor = "white";
            (document.querySelector('.close') as HTMLElement).style.color = "#011784";
          }
        } else if ((this.currentUrl != '/Home') && screenWidth > 991) {
          // console.log('normal screen and other page')
          this.headerColors = '#011784';
          this.imagePath = "assets/campaign_bird.png";
        } else if ((this.currentUrl != '/Home') && screenWidth <= 991) {
          // console.log('responsive screen and other page')
          this.imagePath = "assets/campaign_bird.png";
          (document.querySelector('.navbar-toggler-icon') as HTMLElement).style.backgroundImage = "url('/assets/toggle-icon.png')";
          (document.querySelector('.navbar-toggler') as HTMLElement).style.backgroundColor = "#011784";
          (document.querySelector('.close') as HTMLElement).style.color = "white";
        }
      }
    });
  }

  activationOfLink(link) {
    let linkName;
    if (link == 'usecase' || link == 'use-case') {
      linkName = 'campaigns'
    }
    if (link == 'Home') {
      linkName = 'home'
    }
    if (link == 'case-studies') {
      linkName = 'caseStudies'
    }
    if (link == 'contact-us') {
      linkName = 'contactUs'
    }
    if (link == 'partners') {
      linkName = 'partnerWithUs'
    }
    if (link == 'WhatsApp-commerce') {
      linkName = 'whatsappCommerce'
    }
    this.clickMe(linkName);
  }
   
  
  hideTop(){

    let topHide:any = document.querySelector('.mainHead');
    
    topHide.style.display = 'none';

  }
  ngOnInit(): void {
     $(this).removeClass("actives");
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // console.log('hhhhhhh',document.documentElement.scrollTop);
    
    if(document.documentElement.scrollTop==0){
      document.getElementById('navTop').classList.add('navbar');
      document.getElementById('navTop').classList.remove('fixed-top');
      
    }
    let screenWidth = screen.width;   
    if (document.documentElement.scrollTop > 0 && document.documentElement.scrollTop < 50) {
      this.currentUrl == '/Home' || this.currentUrl == '/' ? this.headerColors = 'white' : this.headerColors = '#011784';
      document.getElementById('navTop').classList.add('navbar');
      document.getElementById('navTop').classList.remove('fixed-top');
    } else if (document.documentElement.scrollTop > 51) {
      this.currentUrl == '/Home' || this.currentUrl == '/' ? this.headerColors = '#011784' : this.currentUrl != '/Home' ? this.headerColors = '#011784' : true;
      document.getElementById('navTop').classList.add('fixed-top');
    }
    if (screenWidth <= 991) {
      document.getElementById('navTop').classList.add('navbar');
      document.getElementById('navTop').classList.remove('fixed-top');
    }
  }

  clickMe(linkName) {
    // console.log('bypage', linkName)
    document.querySelector('.actives').classList.remove('actives')
    if (linkName == 'home') {
      let temp = document.getElementById("home")
      $(temp).addClass("actives");
    } else if (linkName == 'campaigns') {
      let temp = document.getElementById("navbarDropdownCampaigns")
      $(temp).addClass("actives");
    } else if (linkName == 'caseStudies') {
      let temp = document.getElementById("service")
      $(temp).addClass("actives");
    } else if (linkName == 'contactUs') {
      let temp = document.getElementById("navbarDropdownContactUs")
      $(temp).addClass("actives");
    } else if (linkName == 'partnerWithUs') {
      let temp = document.getElementById("partners")
      $(temp).addClass("actives");
    }else if (linkName == 'whatsappCommerce') {
      let temp = document.getElementById("commerce")
      $(temp).addClass("actives");
    }
  }

  

  headerFun() {
    this.headerBoolean = !this.headerBoolean;
    this.headerBrand = !this.headerBrand;
  }

  chengeLogoColorToWhite() {
    if (this.currentUrl == '/Home' || this.currentUrl == '/') {
      this.imagePath = "assets/campaign_bird.png";
      (document.querySelector('.navbar-toggler-icon') as HTMLElement).style.backgroundImage = "url('/assets/toggle-icon-black.png')";
      (document.querySelector('.navbar-toggler') as HTMLElement).style.backgroundColor = "white";
      (document.querySelector('.close') as HTMLElement).style.color = "#011784";
    }
  }

  chengeLogoColorToColored() {
    this.hamburgerOpened = true;
    if (this.currentUrl == '/Home' || this.currentUrl == '/') {
      this.imagePath = "assets/campaign_bird.png";
      (document.querySelector('.navbar-toggler-icon') as HTMLElement).style.backgroundImage = "url('/assets/toggle-icon.png')";
      (document.querySelector('.navbar-toggler') as HTMLElement).style.backgroundColor = "#011784";
      (document.querySelector('.close') as HTMLElement).style.color = "white";
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let screenWidth = window.innerWidth;
    // console.log('screenWidth', screenWidth);
    if (screenWidth > 991) {
      this.imagePath = "assets/campaign_bird.png"
    } else if ((this.currentUrl == '/Home' || this.currentUrl == '/') && screenWidth <= 991) {
      this.imagePath = "assets/campaign_bird.png"
    }
  }

}


