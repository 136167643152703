<!--====== FEATRES TWO PART START ======-->
<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-5 col-sm-12 col-12  first">
                <div class="banner_content">
                    <h1 class="title_text" style="color:#201c47">
                        Experience The Best <br>
                        Services, On <span class="section-color" style="color: #ff7135;">eQuibiz’s</span><br>
                        <span class="color-blue" style="color: #0579ff;"> B2B</span> Deal-Making <br>
                        Platform 
                    </h1>

                </div>
            </div>
            <div class="col-lg-7 col-sm-12 col-12 last">
                <div class="outerBannerimg">
                    <img src="/assets/service.png" class="attachment-full size-full banner_image" alt="Service Banner">
                </div>
            </div>
        </div>
    </div>
</section>
<!--All Services-->
<section class="allservices">
    <div class="container ">
        <div class="row mt-5">
            <div class="col-sm-12"  data-aos="fade-up" data-aos-duration="1000">
                <div class="text-center">
                    <h1>All Services</h1>
                </div>
            </div>
        </div>
        <div class="row mt-5 justify-content-center">
            <div class="col-sm-12">
                <div class="row featurette d-flex">
                    <div class="col-md-5 offset-md-1 order-md-2 manageCol first " >
                        <h2 class="featurette-heading" data-aos="fade-up" data-aos-duration="1000">01.</h2>
                        <h3 data-aos="fade-up" data-aos-duration="1300">Partner's Profiling</h3>
                        <ul>
                            <li data-aos="fade-up" data-aos-duration="1600">
                                Equibiz’s onboarding process secures only
                                KYC compliant and trusted B2B partners
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1700"> Organised display of seller’s details to buyer’s, in
                                order to facilitate informed deal booking over Equibiz
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1800"> Dealing with trusted B2B players enables partners to
                                focus on the growth of the business rather than
                                managing risk</li>
                            <li data-aos="fade-up" data-aos-duration="1900">Connect with verified B2B partners and brands
                                across the country</li>
                        </ul>
                    </div>
                    <div class="col-md-5 order-md-1 manageCol last"  data-aos="fade-up" data-aos-duration="1500">
                        <img src="assets/png/services1.png" class="w-100" alt="Partner's Profiling">
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-5 justify-content-center">
            <div class="col-sm-12">
                <div class="row featurette d-flex">
                    <div class="col-md-5 order-md-1 manageCol first">
                        <h2 class="featurette-heading" data-aos="fade-up" data-aos-duration="1000">02.</h2>
                        <h3 data-aos="fade-up" data-aos-duration="1300">Logistics</h3>
                        <ol>
                            <li data-aos="fade-up" data-aos-duration="1500">Hassle-free logistic services provided by Equibiz along
                                with multiple deal booking options help partners to
                                optimise business output</li>
                            <li data-aos="fade-up" data-aos-duration="1600">Basic QC of stock before pick up</li>
                            <li data-aos="fade-up" data-aos-duration="1700">Enable pick-up and drop facility at fulfilment centers for
                                time and cost optimisation</li>
                            <li data-aos="fade-up" data-aos-duration="1800">Track the status of your order</li>
                            <li data-aos="fade-up" data-aos-duration="1900">Flexibility to choose logistic options for better time and
                                cost management</li>
                            <li data-aos="fade-up" data-aos-duration="2000">Last miles pickup and delivery for your convenience</li>
                        </ol>
                    </div>
                    <div class="col-md-5 offset-md-1 order-md-2 manageCol last">
                        <img src="assets/png/services2.svg" class="w-100" alt="Logistics " data-aos="fade-up" data-aos-duration="1500">
                    </div>
                </div>
            </div>
        </div>
        <div class="row  mt-5 justify-content-center">
            <div class="col-sm-12">
                <div class="row featurette d-flex">
                    <div class="col-md-5 offset-md-1 order-md-2 manageCol first" >
                        <h2 class="featurette-heading" data-aos="fade-up" data-aos-duration="1000">03.</h2>
                        <h3 data-aos="fade-up" data-aos-duration="1300">Secured Payment
                            Mechanism</h3>
                        <ul class="text-secondary">
                            <li data-aos="fade-up" data-aos-duration="1500">Simple onboarding process with virtual account number
                                to track all payment and receipts
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1600">Nodal account to hold payment for 0 risk</li>
                            <li data-aos="fade-up" data-aos-duration="1700">Seamless receipt and payment of funds through
                                Net Banking</li>
                            <li data-aos="fade-up" data-aos-duration="1800">Delivery vs payment mechanism protects partners from
                                any potential loss</li>
                        </ul>
                    </div>
                    <div class="col-md-5 order-md-1 manageCol last" >
                        <img src="assets/png/services3.png" class="w-100" alt="Secured Payment Mechanism" data-aos="fade-up" data-aos-duration="1500">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Upcoming Services-->
<section>
    <div class="container outerUpcoming">
        <h4  data-aos="fade-up">Upcoming <br>Service</h4>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" data-aos="fade-up" data-aos-duration="1500">
                <div class="upcomingSer up1">
                    Instant Invoicing
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" data-aos="fade-up" data-aos-duration="1800">
                <div class="upcomingSer up2">
                    Easy Finance
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" data-aos="fade-up" data-aos-duration="2000">
                <div class="upcomingSer up3">
                    E-way Bill
                </div>
            </div>
        </div>
    </div>
</section>



<!--How it works desktop-->
<section id="services" class="howitworks-color howitDesk">
    <div class="text-center">
        <h4 data-aos="fade-up" data-aos-duration="500">How it works</h4>
    </div>

    <div class="album py-5" class="howitworks-color">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 my-4 outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                    <div class="innerCard" >
                        <div class=" " data-aos="fade-up" data-aos-duration="1000">
                            <i class="icofont-simple-smile">
                                <img src="assets/prebook.svg" class="showImg" alt="Prebook">
                                <img src="assets/prebook1.svg" class="hovershowImg"  alt="Prebook1">
                            </i>
                            <div class="card-body my-4">
                                <h6 class="card-title text-center font-weight-bold" data-aos="fade-up" data-aos-duration="1300">Pre-booking Deal Amount</h6>
                                <p class="card-text text-muted text-center" data-aos="fade-up" data-aos-duration="1500">Buyers prefund the wallet with the prebooking deal amount,
                                    platform and logistic charges.
                                    Places pre-book request with seller on eQuibiz</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  my-4 outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                    <div class="innerCard ">
                        <div class="" data-aos="fade-up" data-aos-duration="1000">
                            <i class="icofont-simple-smile">
                                <img src="assets/modify.svg" class="showImg">
                                <img src="assets/modify1.svg" class="hovershowImg">
                            </i>
    
                            <div class="card-body my-4">
                                <h6 class="card-title text-center font-weight-bold" data-aos="fade-up" data-aos-duration="1300">Modify Order</h6>
                                <p class="card-text text-muted text-center" data-aos="fade-up" data-aos-duration="1500">Seller accepts or rejects the request. If rejected, the
                                    buyer is notified and can now initiate a
                                    new trade. Pre-booking amount platform and logistic charges are deducted from buyer’s
                                    wallet upon acceptance of the trade by the seller</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  my-4 outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                    <div class="innerCard ">
                        <div class="" data-aos="fade-up" data-aos-duration="1000">
                            <i class="icofont-simple-smile">
                                <img src="assets/remaining.svg" class="showImg">
                                <img src="assets/remaining1.svg" class="hovershowImg">
                            </i>
    
                            <div class="card-body my-4">
                                <h6 class="card-title text-center font-weight-bold" data-aos="fade-up" data-aos-duration="1300">Remaining Payment</h6>
                                <p class="card-text text-muted text-center" data-aos="fade-up" data-aos-duration="1500">On acceptance, the deal is confirmed and the buyer must
                                    transfer the balance payment to the supplier's account</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 my-4 outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                    <div class="innerCard ">
                        <div class="" data-aos="fade-up" data-aos-duration="1000">
                            <i class="icofont-simple-smile">
                                <img src="assets/picks.svg" class="showImg">
                                <img src="assets/picks1.svg" class="hovershowImg">
                            </i>
    
                            <div class="card-body my-4">
                                <h6 class="card-title text-center font-weight-bold" data-aos="fade-up" data-aos-duration="1300">Picks Up the Stock</h6>
                                <p class="card-text text-muted text-center" data-aos="fade-up" data-aos-duration="1500">Once stock status is updated as ‘Ready’ by the seller,
                                    eQuibiz arranges a QC and a logistic partner picks up the stock, as per the seller's
                                    instructions</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  my-4 outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                    <div class="innerCard">
                        <div class=" " data-aos="fade-up" data-aos-duration="1000">
                            <i class="icofont-simple-smile">
                                <img src="assets/stock.svg" class="showImg">
                                <img src="assets/stock1.svg" class="hovershowImg">
                            </i>
    
                            <div class="card-body my-4">
                                <h6 class="card-title text-center font-weight-bold" data-aos="fade-up" data-aos-duration="1300">Stock is Dispatched</h6>
                                <p class="card-text text-muted text-center" data-aos="fade-up" data-aos-duration="1500">Stock is dispatched as per the chosen mode of
                                    transportation, buyer is notified on arrival</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  my-4 outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                    <div class="innerCard " data-aos="fade-up" data-aos-duration="1000">
                        <div class=" ">
                            <i class="icofont-simple-smile">
                                <img src="assets/successful.svg" class="showImg">
                                <img src="assets/successful1.svg" class="hovershowImg">
                            </i>
    
                            <div class="card-body my-4">
                                <h6 class="card-title text-center font-weight-bold" data-aos="fade-up" data-aos-duration="1300">Successful Transaction</h6>
                                <p class="card-text text-muted text-center" data-aos="fade-up" data-aos-duration="1500">On acceptance of delivery, the payment is released to
                                    supplier after deduction of platform charges and other statutory deductions such as TDS,
                                    TCS, etc
                                    Parties are notified of a successful transaction</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<!--How it works mobile-->
<section id="services" class="howitworks-color howitMob">
    <div class="text-center">
        <h4 data-aos="fade-up">How it works</h4>
    </div>

    <div class="album py-5" class="howitworks-color">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                             <div class="row">
                                 <div class="col-sm-12">
                                    <div class="outerCommonHeight">
                                        <div class="innerCard">
                                            <div class=" ">
                                                <i class="icofont-simple-smile">
                                                    <img src="assets/prebook.svg" class="showImg">
                                                    <img src="assets/prebook1.svg" class="hovershowImg">
                                                </i>
                                                <div class="card-body my-4">
                                                    <h6 class="card-title text-center my-4 font-weight-bold">Pre-booking Deal Amount</h6>
                                                    <p class="card-text text-muted text-center">Buyers prefund the wallet with the prebooking deal amount,
                                                        platform and logistic charges.
                                                        Places pre-book request with seller on eQuibiz</p>
                                                </div>
                                            </div>
                                        </div>
                                     </div>
                                 </div>
                             </div>
                          </div>
                          <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="outerCommonHeight">
                                        <div class="innerCard">
                                            <div class="">
                                                <i class="icofont-simple-smile">
                                                    <img src="assets/modify.svg" class="showImg">
                                                    <img src="assets/modify1.svg" class="hovershowImg">
                                                </i>
                        
                                                <div class="card-body my-4">
                                                    <h6 class="card-title text-center my-4 font-weight-bold">Modify Order</h6>
                                                    <p class="card-text text-muted text-center">Seller accepts or rejects the request. If rejected, the
                                                        buyer is notified and can now initiate a
                                                        new trade. Pre-booking amount platform and logistic charges are deducted from buyer’s
                                                        wallet upon acceptance of the trade by the seller</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div class="carousel-item">
                              <div class="row">
                                  <div class="col-sm-12">
                                    <div class="outerCommonHeight">
                                        <div class="innerCard">
                                            <div class="">
                                                <i class="icofont-simple-smile">
                                                    <img src="assets/remaining.svg" class="showImg">
                                                    <img src="assets/remaining1.svg" class="hovershowImg">
                                                </i>
                        
                                                <div class="card-body my-4">
                                                    <h6 class="card-title text-center my-4 font-weight-bold">Remaining Payment</h6>
                                                    <p class="card-text text-muted text-center">On acceptance, the deal is confirmed and the buyer must
                                                        transfer the balance payment to the supplier's account</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="outerCommonHeight">
                                        <div class="innerCard ">
                                            <div class="">
                                                <i class="icofont-simple-smile">
                                                    <img src="assets/picks.svg" class="showImg">
                                                    <img src="assets/picks1.svg" class="hovershowImg">
                                                </i>
                        
                                                <div class="card-body my-4">
                                                    <h6 class="card-title text-center my-4 font-weight-bold">Picks Up the Stock</h6>
                                                    <p class="card-text text-muted text-center">Once stock status is updated as ‘Ready’ by the seller,
                                                        eQuibiz arranges a QC and a logistic partner picks up the stock, as per the seller's
                                                        instructions</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="col-sm-12">
                                <div class="outerCommonHeight">
                                    <div class="innerCard">
                                        <div class=" ">
                                            <i class="icofont-simple-smile">
                                                <img src="assets/stock.svg" class="showImg">
                                                <img src="assets/stock1.svg" class="hovershowImg">
                                            </i>
                    
                                            <div class="card-body my-4">
                                                <h6 class="card-title text-center my-4 font-weight-bold">Stock is Dispatched</h6>
                                                <p class="card-text text-muted text-center">Stock is dispatched as per the chosen mode of
                                                    transportation, buyer is notified on arrival</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="col-sm-12">
                                <div class="outerCommonHeight">
                                    <div class="innerCard">
                                        <div class=" ">
                                            <i class="icofont-simple-smile">
                                                <img src="assets/successful.svg" class="showImg">
                                                <img src="assets/successful1.svg" class="hovershowImg">
                                            </i>
                    
                                            <div class="card-body my-4">
                                                <h6 class="card-title text-center my-4 font-weight-bold">Successful Transaction</h6>
                                                <p class="card-text text-muted text-center">On acceptance of delivery, the payment is released to
                                                    supplier after deduction of platform charges and other statutory deductions such as TDS,
                                                    TCS, etc
                                                    Parties are notified of a successful transaction</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>

</section>

<!--Sign in Desktop-->
<section id="tradeId" class="tradeDesk">
    <div class=" trade" data-aos="fade-up" data-aos-duration="1000">
        <!-- <div class="row">
            <div class="col-sm-12">
              <div class="tradeDiv" data-aos="fade-up" data-aos-duration="1300">
                 <div class="mediaDiv">
                    <div class="media">
                        <img src="assets/service.svg" class="mr-3" alt="..." data-aos="fade-up" data-aos-duration="1500">
                        <div class="media-body">
                          <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1700">You are a trader?</h5>
                          <p data-aos="fade-up" data-aos-duration="1900">Make your first trade</p>
                          <a class="joinUs" href="https://trade.equibiz.in/login" target="_blank" data-aos="fade-up" data-aos-duration="2000">Sign In Now</a>
                        </div>
                      </div>
                 </div>
               </div>
            </div>
        </div> -->
        <div class="tradeDiv" data-aos="fade-up" data-aos-duration="1300">
            <div class="mediaDiv">
               <div class="media">
                   <img src="assets/service.svg" class="mr-3" alt="..." data-aos="fade-up" data-aos-duration="1500">
                   <div class="media-body">
                     <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1700">You are a trader?</h5>
                     <p data-aos="fade-up" data-aos-duration="1900">Make your first trade</p>
                     <a class="joinUs" href="https://trade.equibiz.in/login" target="_blank" data-aos="fade-up" data-aos-duration="2000">Sign In Now</a>
                   </div>
                 </div>
            </div>
          </div>
    </div>
</section>

