import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EncrDecrService } from '../shared/EncrDecrService';
import { WebsiteService } from '../website.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-use-case',
  templateUrl: './use-case.component.html',
  styleUrls: ['./use-case.component.css']
})
export class UseCaseComponent implements OnInit {

  trendingproductsConfig = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint:768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useCaseCategories: any;
  campaignId: any;
  useCase: any = [];
  typeOfCampaignsInfo: any;
  currentUrl: string;
  subCategoryId: any;

  abc: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  scrWidth: number;
  logoCount: number;
  successfullyImplementedLogos: any;
  logssss: any;

  constructor(private _websiteService: WebsiteService, private _route: ActivatedRoute, private _EncrDecr: EncrDecrService, private _router: Router, private ngxLoader: NgxUiLoaderService) {

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        console.log('this.currentUrl......', this.currentUrl);
        if (this.currentUrl.split('/')[1] == 'use-case') {
          this._route.params.subscribe(params => {
            this.campaignId = params["campaignId"];
            console.log('capmain id..', this.campaignId)
          });
        } else if (this.currentUrl.split('/')[1] == 'usecase') {
          this._route.params.subscribe(params => {
            this.subCategoryId = params["subCategoryId"];
          });
        }
      }
    });
  }

  ngOnInit(): void {

    this.ngxLoader.start();
    console.log('currentUrl..', this.currentUrl.split('/')[1])
    console.log('this.campaignId..', this.campaignId)
    this.getAllUseCaseCategoriesWeb();
    if (this.currentUrl.split('/')[1] == 'use-case' && this.campaignId != undefined) {
      const data = {
        campaignId: this._EncrDecr.set(environment.keyforencdec, this.campaignId)
      }
      this._websiteService.getUseCaseByCampaignIdWeb(data).subscribe((data: { [x: string]: any; }) => {
        if (data['status']) {
          this.useCase = data['data'].length != 0 ? data['data'] : [];
          this.typeOfCampaignsInfo = data['data'].length != 0 ? data['data'][0]['typeOfCampaignsInfo'] : [];
          this.getSuccessfullyImplementedLogos(this.useCase[0]['responseData']?.logo);
          this.logssss = this.useCase[0]['responseData']?.logo;

        }
      });
    } else if (this.currentUrl.split('/')[1] == 'usecase' && this.subCategoryId != undefined) {
      this.getuseCaseData(this.subCategoryId);
    } else {
      this._websiteService.getOneUseCaseWeb().subscribe((data: { [x: string]: any; }) => {
        if (data['status']) {
          this.useCase = data['data'].length != 0 ? data['data'] : [];
          this.typeOfCampaignsInfo = data['data'].length != 0 ? data['data'][0]['typeOfCampaignsInfo'] : [];
          this.getSuccessfullyImplementedLogos(this.useCase[0]['responseData']?.logo);
          this.logssss = this.useCase[0]['responseData']?.logo;

        }
      });
    }
    this.ngxLoader.stop();

    (function ($) {
      $(document).ready(function () {
        $('.logo-carousel').slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: false,
          pauseOnHover: false,
          responsive: [{
            breakpoint: 768,
            settings: {
              slidesToShow: 4
            }
          }, {
            breakpoint: 520,
            settings: {
              slidesToShow: 2
            }
          }]
        });
      });
    })(jQuery);
  }

  getuseCaseData(subCategoryId: any) {
    this.ngxLoader.start();
    console.log('subCAtegoryId...', subCategoryId);
    const useCaseIdData = {
      useCaseId: this._EncrDecr.set(environment.keyforencdec, subCategoryId)
    }
    this._websiteService.getUseCaseByIdWeb(useCaseIdData).subscribe((data: { [x: string]: any; }) => {
      if (data['status']) {
        document.getElementById("scrolledHere").scrollIntoView();
        this.useCase = data['data'].length != 0 ? data['data'] : [];
        this.typeOfCampaignsInfo = data['data'].length != 0 ? data['data'][0]['typeOfCampaignsInfo'] : [];
        this.getSuccessfullyImplementedLogos(this.useCase[0]['responseData']?.logo);
        this.logssss = this.useCase[0]['responseData']?.logo;
      }
    });
    this.ngxLoader.stop();
  }

  getAllUseCaseCategoriesWeb() {
    this._websiteService.getAllUseCaseCategoriesWeb().subscribe((data: { [x: string]: any; }) => {
      if (!data['status']) {
        // this._toastr.error(data.msg, 'Warning!');
      }
      this.useCaseCategories = data['data'];
      console.log('useCaseCategories...', this.useCaseCategories)
    });
  }

  getSuccessfullyImplementedLogos(res) {
   
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 1300) {
      this.logoCount = 3;
    } else if (this.scrWidth < 1300 && this.scrWidth > 1025) {
      this.logoCount = 3;
    }
    else if (this.scrWidth <= 991) {
      this.logoCount = 2;
    }
    return this.chunks(res, this.logoCount);
  }


  chunks(array, size) {
    let R = [];
    for (let i = 0, len = array.length; i < len; i += size) {
      R.push(array.slice(i, i + size));
    }
    console.log('RRRRRRR', R);

    return R;
  }

}
