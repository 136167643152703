import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ServicesComponent } from './services/services.component';
import { PartnersComponent } from './partners/partners.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { FaqComponent } from './faq/faq.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UseCaseComponent } from './use-case/use-case.component';
import { FreshCampaignsComponent } from './fresh-campaigns/fresh-campaigns.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CaseStudyComponent } from './case-study/case-study.component';
import { ViewCaseStudyComponent } from './view-case-study/view-case-study.component';
import { CaseStudyDataComponent } from './case-study-data/case-study-data.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { WhatsappcommerceComponent } from './whatsappcommerce/whatsappcommerce.component';
import { EventcalendarComponent } from './eventcalendar/eventcalendar.component';
import { IplComponent } from './ipl/ipl.component';
import { SupplyChainVisibilityComponent } from './supply-chain-visibility/supply-chain-visibility.component';
import { CsrComponent } from './csr/csr.component';


@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    ServicesComponent,
    PartnersComponent,
    ContactUsComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    FaqComponent,
    UseCaseComponent,
    FreshCampaignsComponent,
    PrivacyPolicyComponent,
    CaseStudyComponent,
    ViewCaseStudyComponent,
    CaseStudyDataComponent,
    WhatsappcommerceComponent,
    EventcalendarComponent,
    IplComponent,
    SupplyChainVisibilityComponent,
    CsrComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    NgxUiLoaderModule,
    SlickCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
