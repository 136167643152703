<section id="about" class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top" >
    
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-lg-5 col-sm-12 col-12 first">
            <div class="banner_content">
                <h1 style="color: #201c47;">
                    End To End <span class="color-blue" style="color: #0579ff;"> Solution</span> <br>
                    <span class="section-color" style="color: #201c47;">For The Ultimate</span><br>
                    <span class="section-color" style="color:#ff7135">Marketplace</span>
                </h1>
            </div>
        </div>
            <div class="col-lg-7  col-sm-12 col-12 last">
                <div class="outerBannerimg">
                    <img src="assets/about-us.jpg" class="banner_image attachment-full size-full " alt="About Us Banner">
                </div>
            </div>
            
        </div>
    </div>
</section>


<!--Our Journey-->
<section class="aboutUs journeyDesk">
    <div class="firstPerson"><img src="/assets/svg/first-girl.svg" alt=""></div>
    <div class="secondPerson"><img src="/assets/svg/second-boy.svg" alt=""></div>
    <div class="thirdPerson"><img src="/assets/svg/third-boy.svg" alt=""></div>
    <div class="lightPoll"><img src="/assets/svg/ligtpoll.svg" alt=""></div>
    <div class="fourthPerson"><img src="/assets/svg/fourth-girl.svg" alt=""></div>
    <div class="container inneraboutUs">
      <div class="row hiddenOverflow" >
        <div class="col-sm-12 text-center">
          <h1 data-aos="fade-up"  data-aos-duration="600"> About Us</h1>
          <p data-aos="fade-up"  data-aos-duration="1000">Operational in 2020, eQuibiz is more than just an aggregator. <br>
            We are a unique electronic trading platform, connecting buyers<br>
            and sellers across boundaries, and creating a marketplace based<br>
            on the principles of price discovery  and transparency thereby<br>
            helping partners improve their margins.</p>
        </div>
      </div>
      <div class="row ourJourney">
        <div class="col-sm-12 text-center">
          <h1 data-aos="fade-up"  data-aos-duration="800">Our Journey</h1>
        </div>
      </div>
      <div class="row Year">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 outerCommonHeight">
            <div class="hrLine">
                <div class="numberRound">
                  <div data-aos="fade-up"  data-aos-duration="1300">Sept</div>
                  <div data-aos="fade-up"  data-aos-duration="1800">2019</div>
                </div>
                <p data-aos="zoom-in" data-aos-duration="2000" class="hiddenOverflow">The idea of setting up a B2B Trading<br> Ecosystem was decided upon in<br> Sept 2019</p>
                <div class="dottedhrLine"></div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 outerCommonHeight">
            <div class="hrLine">
                <div class="numberRound">
                  <div data-aos="fade-up"  data-aos-duration="1300">Dec</div>
                  <div data-aos="fade-up"  data-aos-duration="1800">2019</div>
                </div>
                <p data-aos="fade-up"  data-aos-duration="2000" class="hiddenOverflow">Digixpress Trade Private Limited<br> was set up by Mr. Sunil Kumar in<br> Dec 2019</p>
                <div class="dottedhrLine"></div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 outerCommonHeight">
            <div class="hrLine">
                <div class="numberRound">
                  <div data-aos="fade-up"  data-aos-duration="1300">Year</div>
                  <div data-aos="fade-up"  data-aos-duration="1800">2021</div>
                </div>
                <p data-aos="fade-up"  data-aos-duration="2000" class="hiddenOverflow">eQuibiz was launched in the year 2020<br> to organize B2B Marketplace with the<br> help of technology
                </p>
            </div>
        </div>
      </div>
      <div class="row bgprodInfo">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center hiddenOverflow">
          <div class="prodInfo">
            <div class="count">
              <span id="ct">{{citiescount + "+"}}</span>
            </div>
            <div class="prodTitle" data-aos="fade-up"  data-aos-duration="1000">
              Cities
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center hiddenOverflow">
          <div class="prodInfo">
            <div class="count">
              <span id="cct">{{customercount + "+"}}</span>
            </div>
            <div class="prodTitle" data-aos="fade-up"  data-aos-duration="1000">
              Customers
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center hiddenOverflow" >
          <div class="prodInfo">
            <div class="count">
              <span id="ccct">{{productscount + "+"}}</span>
            </div>
            <div class="prodTitle" data-aos="fade-up"  data-aos-duration="1000">
              Products Sold
            </div>
          </div>
        </div>
      </div>
    </div>
</section>

<section class="mt-5 aboutUs journeyMob">
  
  <div class="container inneraboutUs">
    <div class="row">
      <div class="col-sm-12 text-center" >
        <h1 data-aos="fade-up" data-aos-duration="600">About Us</h1>
        <p data-aos="fade-up" data-aos-duration="1000">Operational in 2020, eQuibiz is more than just an aggregator. 
          We are a unique electronic trading platform, 
          connecting buyers and sellers across boundaries, 
          and creating a marketplace based on the principles of <b>price discovery</b> and <b>transparency</b>;
          thereby helping partners improve their margins.</p>
      </div>
      <div class="mbAbout">
        <img class="w-100" src="/assets/mbAbout.png" alt="">
      </div>
    </div>
    <div class="row ourJourney">
      <div class="col-sm-12 text-center">
        <h1 data-aos="fade-up" data-aos-duration="1000">Our Journey</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="row">
                  <div class="col-xl-12 col-12 outerCommonHeight">
                    <div class="hrLine">
                        <div class="numberRound">
                          <div data-aos="fade-up" data-aos-duration="800">Sept</div>
                          <div data-aos="fade-up" data-aos-duration="1000">2019</div>  
                        </div>
                        <p data-aos="fade-up" data-aos-duration="1300">The idea of setting up a B2B Trading Ecosystem was decided upon in Sept 2019</p>
                        <div class="dottedhrLine"></div>
                    </div>
                </div>
                </div>
            </div>
            <div class="carousel-item">
              <div class="col-xl-12 col-12 outerCommonHeight">
                <div class="hrLine">
                    <div class="numberRound">
                      <div data-aos="fade-up" data-aos-duration="800">Dec</div>
                      <div data-aos="fade-up" data-aos-duration="1000">2019</div>
                    </div>
                    <p data-aos="fade-up" data-aos-duration="1300">Digixpress Trade Private Limited was set up by Mr. Sunil Kumar in Dec 2019
                    </p>
                    <div class="dottedhrLine"></div>
                </div>
            </div>
            </div>
            <div class="carousel-item">
              <div class="col-xl-12 col-12 outerCommonHeight">
                <div class="hrLine">
                    <div class="numberRound">
                      <div data-aos="fade-up" data-aos-duration="800">Year</div>
                      <div data-aos="fade-up" data-aos-duration="1000"> 2021</div>
                    </div>
                    <p data-aos="fade-up" data-aos-duration="1300">eQuibiz was launched in the year 2020 to organize B2B Marketplace with the help of technology
                    </p>
                      <div class="dottedhrLine"></div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row bgprodInfo">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="prodInfo firstProd">
          <div class="count">
            <span id="ct">{{citiescount + "+"}}</span>
          </div>
          <div class="prodTitle" data-aos="fade-up" data-aos-duration="1000">
            Cities
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="prodInfo">
          <div class="count">
            <span id="cct">{{customercount + "+"}}</span>
          </div>
          <div class="prodTitle" data-aos="fade-up" data-aos-duration="1000">
            Customers
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="prodInfo lastProd">
          <div class="count">
            <span id="ccct">{{productscount + "+"}}</span>
          </div>
          <div class="prodTitle" data-aos="fade-up" data-aos-duration="1000">
            Products Sold
          </div>
        </div>
      </div>
    </div>
    
  </div>
</section>

<!--Our Mission-->
<section class="ourMission ourMissionDesk">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  ">
        <h1 class="headingOur hiddenOverflow" data-aos="fade-up" data-aos-duration="1000">Our<br> Mission<br> & Vision</h1>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm- col-12">

      </div>
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
        <div class="media hiddenOverflow">
          <div class="rectangle pink" data-aos="fade-up" data-aos-duration="1000">
            <img  src="assets/mission.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Mission</h5>
            <p data-aos="fade-up" data-aos-duration="1500">Our mission is to bring together businesses from all over the country - from the smallest to the largest - on a single, fair and transparent B2B platform. As a company, we operate on the principles of honesty and integrity</p>
          </div>
        </div>
        <div class="media hiddenOverflow">
          <div class="rectangle blue " data-aos="fade-up" data-aos-duration="1000">
            <img  src="assets/focus.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Vision</h5>
            <p data-aos="fade-up" data-aos-duration="1500">Our vision is to become a local reference for all products traded over B2B platforms.<br>
              <strong>Think B2B, think eQuibiz</strong> 
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="ourMission ourMissionMob">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-12">
        <h1 class="headingOur hiddenOverflow"  data-aos="fade-up" data-aos-duration="1000">Our  Mission  & Vision</h1>
      </div>
    </div>
    <div class="row">  
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="media hiddenOverflow">
          <div class="rectangle pink rect" data-aos="fade-up" data-aos-duration="1000">
            <img  src="assets/mission.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Mission</h5>
            <p data-aos="fade-up" data-aos-duration="1500">Our mission is to bring together businesses from all over the country - from the smallest to the largest - on a single, fair and transparent B2B platform. As a company, we operate on the principles of honesty and integrity</p>
          </div>
        </div>
        <div class="media hiddenOverflow">
          <div class="rectangle blue rect" data-aos="fade-up" data-aos-duration="1000">
            <img  src="assets/focus.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Vision</h5>
            <p data-aos="fade-up" data-aos-duration="1500">Our vision is to become a local reference for all products traded over B2B platforms.<br>
              <strong>Think B2B, think eQuibiz</strong> 
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Our Speciality-->
<section class="ourSpeciality">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 first ourSpeciFirst">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
             <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 hiddenOverflow" data-aos="fade-up" data-aos-duration="1000">
                <div class="oursoultionCard ourSpecialitymargin ">
                  <div class="rectangle pink" data-aos="fade-up" data-aos-duration="1300">
                    <img src="assets/briefcase.svg">
                  </div>
                  <h4 data-aos="fade-up" data-aos-duration="1500">Organized<br> Marketplace</h4>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 firstSpec hiddenOverflow" data-aos="fade-up" data-aos-duration="1000">
                <div class="oursoultionCard ourSpecialitymargin">
                  <div class="rectangle blue" data-aos="fade-up" data-aos-duration="1300">
                    <img src="assets/bitcoin.svg">
                  </div>
                  <h4 data-aos="fade-up" data-aos-duration="1500">No<br> Default Risk</h4>
                </div>
              </div>
             </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 secSpec">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 hiddenOverflow" data-aos="fade-up" data-aos-duration="1000">
                <div class="oursoultionCard ourSpecialitymargin ">
                  <div class="rectangle blue" data-aos="fade-up" data-aos-duration="1300">
                    <img src="assets/shape.svg">
                  </div>
                  <h4 data-aos="fade-up" data-aos-duration="1500">Surged<br> Demand</h4>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 firstSpec hiddenOverflow" data-aos="fade-up" data-aos-duration="1000">
                <div class="oursoultionCard ourSpecialitymargin">
                  <div class="rectangle pink" data-aos="fade-up" data-aos-duration="1300">
                    <img src="assets/process1.png">
                  </div>
                  <h4 data-aos="fade-up" data-aos-duration="1500">Seamless<br> Process & Logistics</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 sm-d-none">

      </div>
      
      <div class="col-xl-4 col-lg-4 col-md-4 ourSpec ">
        <h1 class="headingOurspec hiddenOverflow"   data-aos="fade-up" data-aos-duration="1000">Our<br> Speciality</h1>
      </div>
    </div>
  </div>
</section>

<!--Our Team-->
<section class="cs ourDesktop outerCustomer">
  <div class="container ">
    <div class="row">
      <div class="col-xl-12" >
        <h1 class="text-center" data-aos="fade-up" data-aos-duration="1000">Our Team</h1>
        <p class="text-center" data-aos="fade-up" data-aos-duration="1300">The Ultimate Marketplace needs the Ultimate Team</p>
      </div>
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
        <div class="outerCard">
          <div class="profile" data-aos="fade-up" data-aos-duration="1300">
            <img  src="assets/sunilkumar.jpeg" alt="Mr. Satish Kumar">
          </div>
          <div class="info">
            <div class="name" data-aos="fade-up" data-aos-duration="1400">Sunil Kumar</div>
            <div class="location" data-aos="fade-up" data-aos-duration="1500">CEO & Founder</div>
          </div>
          <div class="profileDesc" data-aos="fade-up" data-aos-duration="1600">
            A banking professional worked for 20 years in the industry both in PSU and Foreign Banks. Sunil started his entrepreneurial journey in 2018 by setting up his own venture in the area of mobility and at the same time laid the foundation of Equibiz.
          </div>
       </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
       <div class="outerCard">
          <div class="profile" data-aos="fade-up" data-aos-duration="1300">
            <img  src="assets/mahaveer.jpg" alt="Mr. Mahaveer Muttha">
          </div>
          <div class="info">
            <div class="name" data-aos="fade-up" data-aos-duration="1400">Mahaveer Muttha </div>
            <div class="location" data-aos="fade-up" data-aos-duration="1500">CTO (Chief Technology Officer)</div>
          </div>
          <div class="profileDesc" data-aos="fade-up" data-aos-duration="1600">
              Mr. Mahaveer Muttha has 10+ years of experience in Software product development and specialized in enterprise mobile and web applications. Besides this, he is also an organizer at Google developer group, Pune since 2014.
          </div>
       </div>
    </div>
  </div>
  </div>
</section>

<section class="ourTeamMobile">
  <div class="container ourTeam">
    <div class="row">
      <div class="col-sm-12 text-center"  >
        <h1 class="text-center" data-aos="fade-up" data-aos-duration="1000">Our Team</h1>
          <p class="text-center mb-5" data-aos="fade-up" data-aos-duration="1000">The Ultimate Marketplace needs the Ultimate Team</p>
      </div>
    </div>
  
  <div class="row">
    <div class="col-sm-12">
      <div id="carouselOurSolution" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselOurSolution" data-slide-to="0" class="active"></li>
           <li data-target="#carouselOurSolution" data-slide-to="1"></li>
         <!-- <li data-target="#carouselOurSolution" data-slide-to="2"></li> -->
        </ol>
        <div class="carousel-inner">
          <div class="rectangleOne">

          </div>
          <div class="rectangleTwo">
            
          </div>
          <div class="carousel-item active">
            <div class="row">
              <div class="col-sm-12 outerCommonHeight">
                <div class="outerCard">
                  <div class="profile">
                    <img src="assets/sunilkumar.jpeg" alt="Mr. Satish Kumar">
                  </div>
                  <div class="info">
                    <div class="name">Sunil Kumar</div>
                    <div class="location">CEO & Founder</div>
                  </div>
                  <div class="profileDesc">
                    A banking professional worked for 20 years in the industry both in PSU and Foreign Banks. Sunil started his entrepreneurial journey in 2018 by setting up his own venture in the area of mobility and at the same time laid the foundation of Equibiz.
                  </div>
               </div>
              </div>
            </div>
          </div>
          <div class="carousel-item  ">
            <div class="row">
              <div class="col-sm-12">
                 <div class="outerCard">
                  <div class="profile">
                    <img src="assets/mahaveer.jpg" alt="Mr. Mahaveer Muttha">
                  </div>
                  <div class="info">
                    <div class="name">Mr. Mahaveer Muttha</div>
                    <div class="location">CTO (Chief Technology Officer)</div>
                  </div>
                  <div class="profileDesc">
                    Mr. Mahaveer Muttha has 10+ years of experience in Software product development and specialized in enterprise mobile and web applications. Besides this, he is also an organizer at Google developer group, Pune since 2014.
                  </div>
               </div> 
              </div>
            </div>
          </div>
          <!-- <div class="carousel-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="outerCard">
                  <div class="profile">
                    <img src="assets/sunilkumar.jpeg" alt="Mr. Satish Kumar">
                  </div>
                  <div class="info">
                    <div class="name">Sunil Kumar</div>
                    <div class="location">CEO & Founder</div>
                  </div>
                  <div class="profileDesc">
                    A banking professional worked for 20 years in the industry both in PSU and Foreign Banks. Sunil started his entrepreneurial journey in 2018 by setting up his own venture in the area of mobility and at the same time laid the foundation of Equibiz.
                  </div>
               </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="outerCard">
                  <div class="profile">
                    <img src="assets/3.png" alt="Mr. Satish Kumar">
                  </div>
                  <div class="info">
                    <div class="name">Ajay Sharma</div>
                    <div class="location">CEO</div>
                  </div>
                  <div class="profileDesc">
                    Policies and processes are transparent and ensure protection of interest of all partners involved.Charges applicable are low and explicitly  
                  </div>
               </div>
              </div>
            </div>
          </div> -->
        </div>
        
      </div>
    </div>
  </div>

</div>
</section>


<!--Career-->
<div class="container-fluid" id="career">
  <div class="row ">
     <div class="col-lg-12">
       <!-- <div class="career">
          <div class="innerCareer">
            <img src="assets/career-box.png" alt="Career" usemap="#Map">
            <a class="nav-link" [routerLink]="['/contact-us', 1]">
            <map name="Map"> 
              <area shape="rect" coords="78,100,189,136" href="#">
            </map>
          </a>
          </div>
       </div> -->
       <div class="outerCollage deskCollage">
          <div class="careerDiv" data-aos="fade-up" data-aos-duration="1500">
            <div class="backDiv">
              <!-- <img src="assets/collage/5.svg" alt=""> -->
              <div class="mediaDiv">
                <div class="media">
                  <img src="assets/collage/love.svg" class="mr-3" alt="..." data-aos="fade-up" data-aos-duration="1700">
                  <div class="media-body">
                    <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1800">Career</h5>
                    <p data-aos="fade-up" data-aos-duration="1900">Like what eQuibiz offers?</p>
                    <a class="joinUs" [routerLink]="['/contact-us', 1]" data-aos="fade-up" data-aos-duration="2000">Join Us</a>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <div class="firstDiv" data-aos="fade-up" data-aos-duration="500">
              <img src="assets/collage/1.png" alt="">
          </div>
          <div class="secondDiv">
              <div class="topDiv" >
                 <div class="topFirst" data-aos="fade-up" data-aos-duration="1000"><img src="assets/collage/2.png" alt="" ></div>
                 <div class="topSecond" data-aos="fade-up" data-aos-duration="1300"><img src="assets/collage/3.png" alt=""></div>
                 <div class="clearfix"></div>
              </div>
              <div class="bottomDiv" data-aos="fade-up" data-aos-duration="1000">
                <img src="assets/collage/4.png" alt="">
              </div>
          </div>
          <div class="clearfix"></div>
       </div>

       <div class="outerCollage mbCollage">
        <div class="careerDiv" data-aos="fade-up" data-aos-duration="1500">
          <div class="backDiv">
            <div class="mediaDiv">
              <div class="media">
                <img src="assets/collage/love.svg" class="mr-3" alt="..." data-aos="fade-up" data-aos-duration="1700">
                <div class="media-body">
                  <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1800">Career</h5>
                  <p data-aos="fade-up" data-aos-duration="1900">Like what eQuibiz offers?</p>
                  <a class="joinUs" [routerLink]="['/contact-us', 1]" data-aos="fade-up" data-aos-duration="2000">Join Us</a>
                </div>
              </div>
            </div>
          </div>  
        </div>
        <div class="firstDivs" data-aos="fade-up" data-aos-duration="500">
            <img src="assets/collage/11.png" alt="">
        </div>
        <div class="secondDivs">
            <div class="topDiv" >
               <div class="topFirst" data-aos="fade-up" data-aos-duration="1000"><img src="assets/collage/33.png" alt="" ></div>
               <div class="topSecond" data-aos="fade-up" data-aos-duration="1300"><img src="assets/collage/44.png" alt=""></div>
               <div class="clearfix"></div>
            </div>
        </div>
        <div class="clearfix"></div>
     </div>
     </div>     
  </div>
</div>