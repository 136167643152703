<section id="firstSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
                <div class="text-center">
                    <img src="assets/supply/11.png" class="w-75" alt="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="headContent">
                    <h1>Building Trust with Product packs</h1>
                    <p>Build trust and an emotional connection with your customers by sharing the supply chain,
                        certifications and farmer stories.</p>
                </div>
            </div>
            <div class="col-sm-6 px-0 d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
                <img src="assets/supply/1.png" class="w-100" alt="">
            </div>
        </div>
    </div>
</section>
<section id="twoSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
                <div class="text-center">
                    <img src="assets/supply/22.png" class="w-75 " alt="">
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-sm-6 px-0 d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
                <img src="assets/supply/2.png" class="w-75" alt="">
            </div>
            <div class="col-sm-6">
                <div class="headContent">
                    <h4>Your brand has ticked all the right boxes. </h4>
                    <ul>
                        <li>The brand has invested heavily in the organic supply chain.
                        </li>
                        <li>The brand is maintaining the product tracking from the origin to packaging.
                        </li>
                        <li>The brand has received all the necessary certifications.</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>


<section id="thirdSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
                <hr>
                <div class="text-center">
                    <img src="assets/supply/33.png" class="w-75 " alt="">
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-sm-6">
                <div class="headContent otherHead">
                    <h5>Is it enough?<br>
                        What more could a brand need?</h5>
                    <h3>A way to tell your brand/product story through product packs.</h3>
                </div>
            </div>
            <div class="col-sm-6 px-0 d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
                <img src="assets/supply/3.png" class="w-100" alt="">
            </div>
        </div>
    </div>
</section>

<section id="fourSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
                <div class="text-center">
                    <img src="assets/supply/44.gif" class="w-75 " alt="">
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-sm-6 px-0 d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
                <img src="assets/supply/4.gif" class="w-75" alt="">
            </div>
            <div class="col-sm-6">
                <div class="headContent">
                    <h4>How does it work? </h4>
                    <ol>
                        <li>
                            <div class="number">01</div> Scan the QR code.
                        </li>
                        <li>
                            <div class="number">02</div> Get connected on the WhatsApp chatbot.
                        </li>
                        <li>
                            <div class="number">03</div> Get all the information.<br>
                            <span>(Product pack data including manufacturing and expiry date, organic certificates,
                                supply
                                chain tracking going all the way to the farmers, plant videos, farmers’ story video and
                                a
                                lot more)</span>
                        </li>
                    </ol>
                </div>
            </div>

        </div>
    </div>
</section>
<section id="fiveSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h4>Some advantages </h4>
            </div>
        </div>
        <div class="row techDesktop">
            <div class="col-sm-12">
                <div id="carouseltechDesktop" class="carousel slide" data-ride="carousel" (mouseover)="stopCarousel()">
                    <ol class="carousel-indicators usecaseCarousel">
                        <li data-target="#carouseltechDesktop" data-slide-to="0" class="active"></li>
                        <li data-target="#carouseltechDesktop" data-slide-to="1"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad1.png" alt="Low on Budget">
                                        <h6>Low on Budget</h6>
                                        <p>(Cost/reach)</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad2.png" alt="High on engagement ">
                                        <h6>High on engagement</h6>
                                        <p>(User’s PII or personal identifiable information can help us reconnect with
                                            users)</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad3.png" alt="Data insights">
                                        <h6>Data insights</h6>
                                        <p>(reach, engagement, conversions)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad4.png" alt="WhatsApp chatbot">
                                        <h6>WhatsApp chatbot to WhatsApp commerce or e-commerce journey is seamless</h6>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight"
                                    data-aos="fade-up" data-aos-duration="1000">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad5.png" alt="Feedback">
                                        <h6>Collect feedback and testimonials from users</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row techMobile">
            <div class="col-sm-12">
                <div id="carouseltechMobile" class="carousel slide" data-ride="carousel" (mouseover)="stopCarousel()">
                    <ol class="carousel-indicators usecaseCarousel">
                        <li data-target="#carouseltechMobile" data-slide-to="0" class="active"></li>
                        <li data-target="#carouseltechMobile" data-slide-to="1"></li>
                        <li data-target="#carouseltechMobile" data-slide-to="2"></li>
                        <li data-target="#carouseltechMobile" data-slide-to="3"></li>
                        <li data-target="#carouseltechMobile" data-slide-to="4"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad1.png" alt="Low on Budget">
                                        <h6>Low on Budget</h6>
                                        <p>(Cost/reach)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad2.png" alt="High on engagement ">
                                        <h6>High on engagement</h6>
                                        <p>(User’s PII or personal identifiable information can help us reconnect with
                                            users)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad3.png" alt="Data insights">
                                        <h6>Data insights</h6>
                                        <p>(reach, engagement, conversions)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad4.png" alt="WhatsApp chatbot">
                                        <h6>WhatsApp chatbot to WhatsApp commerce or e-commerce journey is seamless</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-sm-12 col-12 text-center outerCommonHeight">
                                    <div class="outerUltimate">
                                        <img src="assets/supply/ad5.png" alt="Feedback">
                                        <h6>Collect feedback and testimonials from users</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="sixSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
                <div class="text-center">
                    <img src="assets/supply/66.png" class="w-75 " alt="">
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-sm-6">
                <div class="headContent">
                    <h3>Retaining consumers is easy if they trust the brand and are emotionally attached to it. </h3>
                </div>
            </div>
            <div class="col-sm-6 px-0 d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
                <img src="assets/supply/6.png" class="w-100" alt="">
            </div>
        </div>
    </div>
</section>
<section id="sevenSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
                <div class="text-center">
                    <img src="assets/supply/77.png" class="w-75 " alt="">
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-sm-6 px-0 d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
                <img src="assets/supply/7.png" class="w-100" alt="">
            </div>
            <div class="col-sm-6">
                <div class="headContent">
                    <p>WhatsApp is the best channel for your brand to build personal relationships at scale. </p>
                    <h2>Get your brand or product story out
                        with WhatsApp.
                        Let’s connect. </h2>
                </div>
            </div>

        </div>
    </div>
</section>