import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component'
import { ServicesComponent } from './services/services.component'
import { PartnersComponent } from './partners/partners.component'
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { FreshCampaignsComponent } from './fresh-campaigns/fresh-campaigns.component';
import { UseCaseComponent } from './use-case/use-case.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { ViewCaseStudyComponent } from './view-case-study/view-case-study.component';
import { WhatsappcommerceComponent } from './whatsappcommerce/whatsappcommerce.component';
import { EventcalendarComponent } from './eventcalendar/eventcalendar.component';
import { IplComponent } from './ipl/ipl.component';
import { SupplyChainVisibilityComponent } from './supply-chain-visibility/supply-chain-visibility.component';
import { CsrComponent } from './csr/csr.component';

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/Home" },
  { path: "about-us", component: AboutUsComponent },
  { path: "contact-us/:id", component: ContactUsComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "schedule-meeting", component: ContactUsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "use-case", component: UseCaseComponent },
  { path: "use-case/:campaignId", component: UseCaseComponent },
  { path: "usecase/:subCategoryId", component: UseCaseComponent },
  { path: "fresh-campaigns", component: FreshCampaignsComponent },
  { path: "partners", component: PartnersComponent },
  { path: "WhatsApp-commerce", component: WhatsappcommerceComponent },
  { path: "supply-chain-visibility", component:SupplyChainVisibilityComponent},
  { path: "Home", component: HomeComponent },
  { path: "all-case-studies", component: HomeComponent },
  { path: "faq", component: FaqComponent },
  { path: "service", component: ServicesComponent },
  { path: "case-studies", component: CaseStudyComponent },
  { path: "case-study/:caseStudyId", component: ViewCaseStudyComponent },
  { path: "event-calendar", component: EventcalendarComponent },
  { path: "ipl", component: IplComponent },
  { path: "csr", component: CsrComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
