import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { OneSignal } from 'onesignal-ngx';
 
import * as AOS from 'aos';
declare var jQuery: any;
declare var $: any;

// import AOS from 'aos/dist/aos';
// import 'aos/dist/aos.css';
 
 

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'demo-project';

  constructor(private router: Router,
    private os: OneSignal) {
    
    this.os.init({
      appId:'6d459534-2ad3-48bd-9000-458b258457a7', 
    })
  }



  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
 
}
@HostListener('window:scroll', [])
  onWindowScroll() {
    AOS.init();
    
    // console.log(document.documentElement.scrollTop);
  }
}


