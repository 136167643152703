<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top partnerOuter">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-5 col-sm-12 col-12 first">
                <div class="banner_content">
                    <h1 class="title_text">
                        Event Calendar
                    </h1>
                    <!-- <p>You can partner with CampaignBird to expand revenue opportunities for your business by delivering
                        more value to your customers.</p> -->
                </div>
            </div>
            <div class="col-lg-7  col-sm-12 col-12 last">
                <div class="outerBannerimg">
                    <img src="assets/event.png" class="" alt="Event Calendar">
                </div>
            </div>

        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="monthHead" style="background:#fffaef;">
                    <h2 style=" color: #ffae01;">March</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonth">
                        <div class="mon">
                            Mar
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonth">
                        <div class="mon">
                            Mar
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonth">
                        <div class="mon">
                            Mar
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonth">
                        <div class="mon">
                            Mar
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonth">
                        <div class="mon">
                            Mar
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="monthHead" style="background:#d6dbed;">
                    <h2 style=" color: #011784;">April</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonths">
                        <div class="mon">
                            April
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonths">
                        <div class="mon">
                            April
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonths">
                        <div class="mon">
                            April
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonths">
                        <div class="mon">
                            April
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="media">
                    <div class="dateMonths">
                        <div class="mon">
                            April
                        </div>
                        <div class="dat">
                            08
                        </div>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0">Event name - this should be 2 lines max</h6>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem modi commodi ratione eveniet, fugiat provident aut debitis qui.</p>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</section>