<!--Banner-->
<section class="iplBanner text-center">
    <img src="assets/ipl-banner.jpg" class="w-100" alt="IPL Banner">
    <div class="textBanner">

        <h1>Here is how your brand can leverage the cricket euphoria in the best ways possible.</h1>
    </div>
</section>


<!--Reward Section-->
<section class="outerRewards">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          
          <p class="text-center" data-aos="fade-up" data-aos-duration="500">There are a lot of ideas that you may have this IPL season but it's really important to have focus on objectives.</p>
          <h4>So, what are you trying to achieve this IPL season?</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="rewards">
            <ul>
              <li>
                <figure>
                  <img src="assets/ipl/1.png" alt="Brand awareness/recall">
                  <figcaption>
                    Brand awareness/recall
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src="assets/ipl/2.png" alt="Lead Generation">
                  <figcaption>Lead<br> Generation</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src="assets/ipl/3.png" alt="Sales">
                  <figcaption>Sales</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <img src="assets/ipl/4.png" alt="New product launch">
                  <figcaption>New<br> product launch</figcaption>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

<section class="queAns"  style="background:#f9fbff;">
    <div class="container">
      <div class="row">
          <div class="col-xl-7 col-md-7 col-sm-7 col-12 first">
              <h3>It's always about reach and engagement</h3>
              <p class="txtCen">Whatever be the goals, you are always looking at reach and personalised engagement for your campaigns. When you are looking for reach, social media channels like Facebook, Instagram are obvious choices. </p>
          </div>
          <div class="col-xl-5 col-md-5 col-sm-5 col-12 second">
              <div class="outerImg">
                  <img src="assets/ipl/reach.png"  alt="" >
              </div>
          </div>
          
      </div>
    </div>
</section>

<!--Technologies Desktop-->
<section class="outerTrade techDesktop">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h4 class="tradeHead" data-aos="fade-up" data-aos-duration="500">Here are some ideas for building reach by running campaigns<br> on Facebook and Instagram</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div id="carouseltechDesktop" class="carousel slide" data-ride="carousel" >
            <!-- <ol class="carousel-indicators usecaseCarousel">
              <li data-target="#carouseltechDesktop" data-slide-to="0" class="active"></li>
              <li data-target="#carouseltechDesktop" data-slide-to="1"></li>
              <li data-target="#carouseltechDesktop" data-slide-to="2"></li>
            </ol> -->
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                    data-aos-duration="500">
                    <div class="outerUltimate">
                      <img src="assets/ipl/achievement.png" alt="Achievement">
                      <p>Predict the winner of today’s match and get a chance to win brand vouchers.</p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="outerUltimate">
                      <img src="assets/ipl/buy.png" alt="Buy">
                      <p>Buy products, score runs and get cricket merchandise. </p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                    data-aos-duration="1000">
                    <div class="outerUltimate">
                      <img src="assets/ipl/solution.png" alt="Solution">
                      <p>Answer a question and get a chance to win a sample or discount voucher. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  



  <!--Technologies Mobile-->
  <section class="outerTrade techMobile">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
            <h4 class="tradeHead" data-aos="fade-up" data-aos-duration="500">Here are some ideas for building reach by running campaigns<br> on Facebook and Instagram</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div id="carouseltechMobile" class="carousel slide" data-ride="carousel">
            <!-- <ol class="carousel-indicators usecaseCarousel">
              <li data-target="#carouseltechMobile" data-slide-to="0" class="active"></li>
              <li data-target="#carouseltechMobile" data-slide-to="1"></li>
              <li data-target="#carouseltechMobile" data-slide-to="2"></li>
              <li data-target="#carouseltechMobile" data-slide-to="3"></li>
              <li data-target="#carouseltechMobile" data-slide-to="4"></li>
              <li data-target="#carouseltechMobile" data-slide-to="5"></li>
              <li data-target="#carouseltechMobile" data-slide-to="6"></li>
              <li data-target="#carouseltechMobile" data-slide-to="7"></li>
              <li data-target="#carouseltechMobile" data-slide-to="8"></li>
            </ol> -->
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="500">
                    <div class="outerUltimate">
                        <img src="assets/ipl/achievement.png" alt="Achievement">
                        <p>Predict the winner of today’s match and get a chance to win brand vouchers.</p>
                      </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                    <div class="outerUltimate">
                        <img src="assets/ipl/buy.png" alt="Buy">
                        <p>Buy products, score runs and get cricket merchandise. </p>
                      </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
                    <div class="outerUltimate">
                        <img src="assets/ipl/solution.png" alt="Solution">
                        <p>Answer a question and get a chance to win a sample or discount voucher. </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>  

  <section class="queAns" style="background:#f9fbff;">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-md-5 col-sm-5 col-12 ">
                <div class="outerImg">
                    <img src="assets/ipl/que.png"  alt="">
                </div>
            </div>
            <div class="col-xl-7 col-md-7 col-sm-7 col-12 ">
                <h3>But how about building  personalised engagement?  Here is a suggestion. </h3>
                <p class="txtCen">Use chatbots for personalised engagement that results in improving success odds for your campaign goals. </p>
            </div>
        </div>
        
    </div>
  </section>

  <section class="queAns">
      <div class="container">
        <div class="row">
            <div class="col-xl-7 col-md-7 col-sm-7 col-12 first">
                <h3>Presenting Chatbots for  WhatsApp, Facebook and Instagram</h3>
                <p class="txtCen">Chatbots are the next generation engagement tools that can improve user experience and engagement for your campaigns.  </p>
            </div>
            <div class="col-xl-5 col-md-5 col-sm-5 col-12 second">
                <div class="outerImg">
                    <img src="assets/ipl/pre.png"  alt="" >
                </div>
            </div>
            
        </div>
      </div>
  </section>

  <section class="queAns" style="background:#f9fbff;">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-md-5 col-sm-5 col-12 ">
                <div class="outerImg">
                    <img src="assets/ipl/chatboat.png"  alt="">
                </div>
            </div>
            <div class="col-xl-7 col-md-7 col-sm-7 col-12 ">
                <h3>Chatbots can build one-on-one engagement at scale and can</h3>
                <ul class="featurette">
                    <li>Collect information </li>
                    <li>Create validated leads </li>
                    <li>Deliver product or brand information</li>
                    <li>Deliver a gift coupon/voucher </li>
                    <li>Enable conversational commerce </li>
                    <li>Collect selfie/product invoice for cashbacks </li>
                    <li>Provide support using automated FAQs, support tickets or chat with a live agent</li>

                </ul>
                
            </div>
        </div>
        
    </div>
  </section>


  <section class="queAns">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-md-7 col-sm-7 col-12 first">
                <h3>Chatbots drive participation and engagement leading to a better ROI for your marketing campaigns. </h3>
                <p class="txtCen">If you have an interesting campaign idea and are looking at building reach and engagement, let's connect.  </p>
            </div>
            <div class="col-xl-5 col-md-5 col-sm-5 col-12 second">
                <div class="outerImg">
                    <img src="assets/ipl/chat.png"  alt="" >
                </div>
            </div>
            
        </div>
        
    </div>
  </section>

  <section class="queAns"  style="background:#f9fbff;">
    <div class="container">
      <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12 text-center">
              <h3>Need more information on campaigns that can be<br> delivered through chatbots?</h3>
              <a href="">Know more</a>
           </div>
      </div>
    </div>
</section>