<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top partnerOuter">
  <div class="container">
      <div class="row align-items-center justify-content-lg-between">
          <div class="col-lg-5 col-sm-12 col-12 first">
              <div class="banner_content">
                  <h1 class="title_text">
                      Case Studies
                  </h1>
               
              </div>
          </div>
          <div class="col-lg-7  col-sm-12 col-12 last">
              <div class="outerBannerimg">
                  <img src="assets/case-studies.png" class="" alt="Case Studies">
              </div>
          </div>
      </div>
  </div>
</section>

<!--Description-->
<section>
  <div class="container">
      <div class="row">
          <div class="col-sm-12 text-center mt-5">
            <h1  data-aos="fade-up"  data-aos-duration="1000">All case studies</h1>
          </div>
        </div>
        <app-case-study-data></app-case-study-data>
  </div>
</section>
