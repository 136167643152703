<!--Desktop-->
<div class="row">
  <div class="col-sm-12 deskTop">
    <div id="carouselCaseDesk" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators usecaseCarousel">
        <li data-target="#carouselCaseDesk" *ngFor="let li of sliderImages; let i = index" [attr.data-slide-to]="i"
          [ngClass]="{'active' : i == 0}"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor='let caseStudy of caseStudies; let i = index' [ngClass]="{'active': i == 0}">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12" *ngFor="let cs of caseStudy; let i = index">
              <div class="box">
                <div class="innerBox">
                  <a [routerLink]="['/case-study', cs?._id]">
                    <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+cs?.descriptionLogo" alt="No Image">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Mobile-->
<div class="row">
  <div class="col-sm-12 mobile">
    <div id="carouselCaseMb" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators usecaseCarousel">
        <li data-target="#carouselCaseMb" *ngFor="let li of sliderImages; let i = index" [attr.data-slide-to]="i"
        [ngClass]="{'active' : i == 0}"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor='let caseStudy of caseStudies; let i = index' [ngClass]="{'active': i == 0}">
          <div class="row">
            <div class="col-12"  *ngFor="let cs of caseStudy; let i = index">
              <div class="box">
                <div class="innerBox">
                  <a [routerLink]="['/case-study', cs?._id]">
                    <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+cs?.descriptionLogo" alt="No Image">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
