<!--Banner-->
<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top partnerOuter">
  <div class="container">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-5 col-sm-12 col-12 first">
        <div class="banner_content">
          <h1 class="title_text">
            Increase Your Revenue with WhatsApp Commerce
          </h1>
          <p>Boost sales & revenue by leveraging WhatsApp, the World's top messaging app.</p>
        </div>
      </div>
      <div class="col-lg-7  col-sm-12 col-12 last">
        <div class="outerBannerimg">
          <img src="assets/whatsappcommerce.png" class="" alt="WhatsApp Commerce Banner">
        </div>
      </div>

    </div>
  </div>
</section>

<!--Book an appointment-->
<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top py-5">
  <div class="container">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6 col-sm-12 col-12 first">
        <div class="banner_content">
          <h2>
            Create an end-to-end shopping experience on WhatsApp for your customers
          </h2>
          <p class="pb-0">Let your customers connect, shop and pay using WhatsApp.</p>
          <div class="bookOuter">
            <a href="https://calendly.com/aishwarya-24" class="bookanAppointment" target="_blank">Book an
              appointment</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6  col-sm-12 col-12 last">
        <div class=" gif text-center">
          <img src="assets/whatsappcommerce/connect.gif" class="" alt="Connect GIF">
        </div>
      </div>

    </div>
  </div>
</section>

<!--Technologies Desktop-->
<section class="outerTrade techDesktop">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="tradeHead" data-aos="fade-up" data-aos-duration="500">How it works</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="carouseltechDesktop" class="carousel slide" data-ride="carousel">
          <!-- <ol class="carousel-indicators usecaseCarousel">
              <li data-target="#carouseltechDesktop" data-slide-to="0" class="active"></li>
              <li data-target="#carouseltechDesktop" data-slide-to="1"></li>
              <li data-target="#carouseltechDesktop" data-slide-to="2"></li>
            </ol> -->
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/whatsappcommerce/how-it-works-connect.png" alt="Connect">
                    <h4>Connect</h4>
                    <p>Enable your customers to connect with your business on WhatsApp with a range of online and
                      offline options like SMS, missed call, QR code, and CTAs on Website, mobile app, social media like
                      Facebook, Instagram, and YouTube.</p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="800">
                  <div class="outerUltimate">
                    <img src="assets/whatsappcommerce/how-it works-buy.png" alt="Shop">
                    <h4>Shop</h4>
                    <p>Let your customers browse your product catalog, view product details, add products to cart and
                      place orders 24x7 giving them a perfect
                      e-commerce shopping experience. </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/whatsappcommerce/how-it-works-pay.png" alt="Pay">
                    <h4>Pay</h4>
                    <!-- <p>Customers can make instant, hassle-free & seamless order payments right from within WhatsApp using any of their UPI payment apps
                        (Google pay, PhonePe, Paytm, Amazon Pay etc.)
                        </p> -->
                    <p>Enable seamless payments through UPI apps like Google Pay, Paytm, PhonePe, and Amazon Pay from
                      inside WhatsApp.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Technologies Mobile-->
<section class="outerTrade techMobile">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="tradeHead" data-aos="fade-up" data-aos-duration="500">How it works</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="carouseltechMobile" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators usecaseCarousel">
            <li data-target="#carouseltechMobile" data-slide-to="0" class="active"></li>
            <li data-target="#carouseltechMobile" data-slide-to="1"></li>
            <li data-target="#carouseltechMobile" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/whatsappcommerce/how-it-works-connect.png" alt="Connect">
                    <h4>Connect</h4>
                    <p>Enable your customers to connect with your business or brand on WhatsApp easily & conveniently
                      using a multitude of online & offline channels: QR Code, Website, Social media, Mobile App, Email,
                      SMS, Missed call. </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                  <div class="outerUltimate">
                    <img src="assets/whatsappcommerce/how-it works-buy.png" alt="Shop">
                    <h4>Shop</h4>
                    <p>Customers can browse your product catalogue, view product details, add products to cart and place
                      orders 24x7 giving them a perfect
                      e-commerce shopping experience. </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/whatsappcommerce/how-it-works-pay.png" alt="Pay">
                    <h4>Pay</h4>
                    <p>Customers can make instant, hassle-free & seamless order payments right from within WhatsApp
                      using any of their UPI payment apps
                      (Google pay, PhonePe, Paytm, Amazon Pay etc.)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--e-commerce strategy -->
<section class="strategy" id="scrolledHere">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center" data-aos="fade-up" data-aos-duration="500" class="tradeHead">Compliment your e-commerce
          strategy<br> with WhatsApp.</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <ul class="tabLink">
              <li><a href="javaScript:void(0)" (click)="goTo('eComPortalBtn')" id="eComPortalBtn"
                  class="active">WhatsApp + e-commerce portal</a></li>
              <li><a href="javaScript:void(0)" (click)="goTo('WhatsAppComBtn')" id="WhatsAppComBtn">WhatsApp
                  commerce</a></li>
            </ul>
          </div>

        </div>
        <div class="row featurette d-flex">
          <div class="col-sm-12" id="whatsappecommerce">
            <div class="row" id="scrolledHereToECom">
              <div class="col-sm-12 text-center">
                <h3>WhatsApp + e-commerce portal</h3>
                <p class="mb-5 mt-4">When you have a large product catalogue and want to sell directly to consumers
                  through your own
                  e-commerce platform, you should look at a combination of WhatsApp and e-commerce platforms like
                  Shopify, WooCommerce etc. for providing a personalized shopping experience.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 offset-md-1 order-md-2 manageCol first ">
                <ul>
                  <li data-aos="fade-up" data-aos-duration="1600">
                    User journey: WhatsApp + e-commerce portal
                    <ol>
                      <li>Connect with the brand or business's WhatsApp verified business account.</li>
                      <li>Initiate WhatsApp conversation using a simple keyword anytime.</li>
                      <li>Click on 'Shop Now' button.</li>
                      <li>Browse, select items, add to cart, checkout/complete order on the e-commerce website or
                        platform.</li>
                      <li>Receive payment link on WhatsApp. Click on the payment link and make payment. (optional).</li>
                      <li>Invoice delivered on WhatsApp post successful payment.
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
              <div class="col-md-5 order-md-1 manageCol last text-center mb-5" data-aos="fade-up"
                data-aos-duration="1500">
                <img src="assets/whatsappcommerce/Whatsappproducts.gif" class="adv" alt="">
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <hr>
          </div>
          <div class="col-sm-12 mt-4" id="whatsappcommerce">

            <div class="row" id="scrolledHereToWhatsAppCommerce">
              <div class="col-sm-12 text-center">
                <h3>WhatsApp commerce</h3>
                <p class="mb-5 mt-4">You can showcase your product catalog and deliver a seamless shopping experience
                  for your customers entirely on WhatsApp.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 offset-md-1 order-md-1 manageCol ">
                <ul>
                  <li data-aos="fade-up" data-aos-duration="1600">
                    User journey: WhatsApp commerce
                    <ol>
                      <li>Connect with the brand or business's WhatsApp verified business account.</li>
                      <li>Initiate WhatsApp conversation using a simple keyword anytime.</li>
                      <li>Click on 'Menu' to browse the list of items.</li>
                      <li>Browse, select items, add to cart, checkout/place order right within WhatsApp.</li>
                      <li>Receive payment link. Make payment using any UPI enabled payment app.</li>
                      <li>Invoice delivered post successful payment.</li>
                    </ol>
                  </li>
                </ul>
              </div>
              <div class="col-md-5 order-md-2 manageCol text-center mb-5" data-aos="fade-up" data-aos-duration="1500">
                <img src="assets/whatsappcommerce/Whatsapp.gif" class="adv" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Engagement WhatsApp-->
<section class="addService">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center" data-aos="fade-up" data-aos-duration="500" class="tradeHead">There’s more to engagement
          on WhatsApp</h1>

      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="media hiddenOverflow">
          <div class="rectangle pinks" data-aos="fade-up" data-aos-duration="1000">
            <img src="assets/1.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Pre-purchase</h5>

          </div>
        </div>
        <div>
          <p data-aos="fade-up" data-aos-duration="1500">Send engaging notifications</p>
          <ul data-aos="fade-up" data-aos-duration="2000">
            <li>Single and multiple product details</li>
            <li>Reminders</li>
            <li>Offers/vouchers</li>
            <li>Newsletters</li>
            <li>Cart abandonment messages</li>
            <li>Greetings and more</li>
          </ul>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="media hiddenOverflow">
          <div class="rectangle blues" data-aos="fade-up" data-aos-duration="1000">
            <img src="assets/2.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Post-purchase
            </h5>

          </div>
        </div>
        <div>
          <p data-aos="fade-up" data-aos-duration="1500">Deliver post-purchase engagement through one-way and two-way
            messages after purchase.
          </p>
          <ul data-aos="fade-up" data-aos-duration="2000">
            <li>Invoice copy</li>

            <li>Order updates on dispatch, delivery etc.</li>

            <li>Request product or service feedback</li>

            <li>Ask for customer referral</li>

            <li>Provide support by enabling FAQs, support tickets and chat with a live agent</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Why BVC-->
<section class="advant">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center" data-aos="fade-up" data-aos-duration="500" class="tradeHead">Why BirdVision?</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="row featurette d-flex">
          <div class="col-md-5 offset-md-1 order-md-2 manageCol first ">
            <ul>
              <li data-aos="fade-up" data-aos-duration="1600">
                Multilingual Communication
                <p>Enable conversations in a language that your customers prefer or understand. All major Indian
                  languages are supported. </p>
              </li>
              <li data-aos="fade-up" data-aos-duration="1700">Personalised service
                <p>Get a dedicated service/helpdesk to help you set up and be business-ready on WhatsApp.</p>
              </li>
              <li data-aos="fade-up" data-aos-duration="1800">
                Integrations
                <p>Seamless integration with payment gateways, CRMs, SFAs, and DMS for a hassle-free user experience.
                </p>
              </li>
              <li data-aos="fade-up" data-aos-duration="1900">
                10+ years of experience
                <p> In workflow automations and customer experience management.</p>

              </li>

            </ul>
          </div>
          <div class="col-md-5 order-md-1 manageCol last text-center mb-5" data-aos="fade-up" data-aos-duration="1500">
            <img src="assets/whatsappcommerce/why-bvc.gif" class="adv" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Logos-->
<!-- <section class="logos">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-center mt-5" data-aos="fade-up" data-aos-duration="500" class="tradeHead">Trusted by some of the largest businesses </h1>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <section class="logo-carousel slider" data-arrows="true">
          <div class="slide logo"><img src="/assets/logos/img1.png"></div>
          <div class="slide logo"><img src="/assets/logos/img3.png"></div>
          <div class="slide logo"><img src="/assets/logos/img2.jpg"></div>
          <div class="slide logo"><img src="/assets/logos/img4.png"></div>
          <div class="slide logo"><img src="/assets/logos/img5.png"></div>
          <div class="slide logo"><img src="/assets/logos/img6.png"></div>
          <div class="slide logo"><img src="/assets/logos/img7.png"></div>
          <div class="slide logo"><img src="/assets/logos/img8.png"></div>
          <div class="slide logo"><img src="/assets/logos/img9.png"></div>
          <div class="slide logo"><img src="/assets/logos/img10.png"></div>
          <div class="slide logo"><img src="/assets/logos/img11.jpg"></div>
          <div class="slide logo"><img src="/assets/logos/img12.png"></div>
          <div class="slide logo"><img src="/assets/logos/img13.jpg"></div>
          <div class="slide logo"><img src="/assets/logos/img14.png"></div>
          <div class="slide logo"><img src="/assets/logos/img15.png"></div>
          <div class="slide logo"><img src="/assets/logos/img16.png"></div>
          <div class="slide logo"><img src="/assets/logos/img17.png"></div>
          <div class="slide logo"><img src="/assets/logos/img18.png"></div>
        </section>
      </div>
    </div>
  </div>
</section> -->