<section class="banner_section home_one_banner deco_wrap d-flex align-items-center banner-margin-top partnerOuter">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-5 col-sm-12 col-12 first">
                <div class="banner_content">
                    <h1 class="title_text">
                        Be a Partner
                    </h1>
                    <p>You can partner with CampaignBird to expand revenue opportunities for your business by delivering
                        more value to your customers.</p>
                </div>
            </div>
            <div class="col-lg-7  col-sm-12 col-12 last">
                <div class="outerBannerimg">
                    <img src="assets/partners.png" class="" alt="Partners Banner">
                </div>
            </div>

        </div>
    </div>
</section>

<!--Partner Form-->
<section id="partners" class="partners-bg-color">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-sm-12 col-12 outD">
                <div class="leftImg">
                    <img src="assets/partnerForm.png" alt="">

                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="outerForm">
                    <form [formGroup]="partnerWithUsForm" (ngSubmit)="onSubmit()">
                        <div class="mb-4">
                            <label for="inputfullName" class="form-label">Name <span>*</span></label>
                            <input type="text" class="form-control" id="inputfullName" placeholder="Enter full name"
                                formControlName="fullName">
                            <div *ngIf="(partnerWithUsFormSubmitted && f.fullName.errors) || (f.fullName.touched && f.fullName.errors)"
                                class="text-danger">
                                <div *ngIf="f.fullName.errors.required">Full name is required
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputEmail" class="form-label">Business Email <span>*</span></label>
                            <input type="text" class="form-control" id="inputEmail" placeholder="Enter business email"
                                formControlName="email">
                            <div *ngIf="(partnerWithUsFormSubmitted && f.email.errors) || (f.email.touched && f.email.errors)"
                                class="text-danger">
                                <div *ngIf="f.email.errors.required">Business email is required
                                </div>
                                <div *ngIf="f.email.errors.email">Business email is Invalid
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputCountry" class="form-label">Country <span>*</span></label>
                            <input type="text" class="form-control" id="inputCountry" placeholder="Enter country"
                                formControlName="country">
                            <div *ngIf="(partnerWithUsFormSubmitted && f.country.errors) || (f.country.touched && f.country.errors)"
                                class="text-danger">
                                <div *ngIf="f.country.errors.required">Country is required
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputPhone" class="form-label">Phone</label>
                            <div class="row">
                                <div class="col-3">
                                    <select class="form-select form-control" id="inputState"
                                        formControlName="stateCode">
                                        <option value="">Select</option>
                                        <option value="+91">+91</option>
                                        <option value="+43">+43</option>
                                    </select>
                                </div>
                                <div class="col-9">
                                    <input type="text" class="form-control" id="inputNumber"
                                        placeholder="Enter mobile or phone" formControlName="contactNumber">
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputInterest" class="form-label">Interested in partner type
                                <span>*</span></label>
                            <select class="form-select form-control" id="inputInterest" formControlName="interestedIn">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let intrests of interestedIn" value="{{intrests.heading}}">
                                    {{intrests.heading}}</option>
                            </select>
                            <div *ngIf="(partnerWithUsFormSubmitted && f.interestedIn.errors) || (f.interestedIn.touched && f.interestedIn.errors)"
                                class="text-danger">
                                <div *ngIf="f.interestedIn.errors.required">Interested in campaign type is required
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="inputDesig" class="form-label">Job Title</label>
                            <input type="text" class="form-control" id="inputDesig"
                                placeholder="Enter job title or Designation" formControlName="designation">
                        </div>
                        <div class="mb-4">
                            <label for="inputDesc" class="form-label">Tell us more about your business
                                requirment</label>
                            <textarea class="form-control" id="inputDesc" rows="3" placeholder="Your message"
                                formControlName="description"></textarea>
                        </div>
                        <div class="mb-4 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                formControlName="confirmation">
                            <label class="form-check-label pri" for="exampleCheck1">I confirm that I wish to be
                                contacted by
                                you regarding your products & services and agree to storing and processing of data as
                                mentioned in CampaignBird's <a routerLink="/privacy-policy">Privacy Statement</a>.
                            </label>
                            <div *ngIf="(partnerWithUsFormSubmitted && f.confirmation.errors) || (f.confirmation.touched && f.confirmation.errors)"
                                class="text-danger">
                                <div *ngIf="f.confirmation.errors.required">Confirmation is required
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" [disabled]="partnerWithUsFormDisableSubmit"
                                class="btn submitBtn">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</section>

<!--Partner Programme-->
<section class="partnerProg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center mb-3" data-aos="fade-up" data-aos-duration="500">Be a partner</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                data-aos-duration="500">
                <div class="outerUltimate">
                    <img src="assets/partner/1.png" alt="Reseller">
                    <h4>Reseller</h4>
                    <p>Deliver great campaigns to your customers with CampaignBird. Whereas you focus on conceptualising
                        the campaign, our platform ensures flawless execution. Once the campaign design is ready, Sit
                        back and track the campaign execution process through live dashboards. Believe us, execution was
                        never this easy.</p>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                data-aos-duration="800">
                <div class="outerUltimate">
                    <img src="assets/partner/2.png" alt="Referral partner ">
                    <h4>Referral partner</h4>
                    <p>You have a great network and want to deliver wonderful user experiences to customers of your
                        network. Just refer to us and we will ensure your relationships are even stronger. You also earn
                        a handsome Finders fee. Contact us to Know more. </p>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                data-aos-duration="1000">
                <div class="outerUltimate">
                    <img src="assets/partner/3.png" alt="Technology partner">
                    <h4>Technology partner</h4>
                    <p>You need a partner to add to your technology portfolio who can provide a communication channel of
                        choice or build a multi channel two way conversational bots that talk to your technology
                        solutions. You are at the right place. Let's talk to make your solutions irresistible for your
                        clients.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Some of our partner-->
<section>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="text-center mt-5" data-aos="fade-up" data-aos-duration="500">Our partners </h1>
            </div>
        </div>
        <div class="row">
            <div class="container">
                <section class="logo-carousel slider" data-arrows="true">
                    <div class="slide logo"><img src="/assets/logos/img12.png"></div>
                    <div class="slide logo"><img src="/assets/logos/img11.jpg"></div>
                    <div class="slide logo"><img src="/assets/logos/img2.jpg"></div>

                </section>
            </div>
        </div>
    </div>
</section>
<ngx-ui-loader> </ngx-ui-loader>