import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';


// import * as $ from 'jquery';
declare var jQuery: any;
declare var $: any;
import { EncrDecrService } from '../shared/EncrDecrService';
import { WebsiteService } from '../website.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  partnerWithUsForm!: FormGroup;
  interestedIn: any;
  page: number = 1;
  partnerWithUsFormSubmitted: boolean = false;
  partnerWithUsFormDisableSubmit: boolean = false;
  constructor(private _formBuilder: FormBuilder,
    private _websiteService: WebsiteService,
    private _router: Router,
    private _EncrDecr: EncrDecrService, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.partnerWithUsForm = this._formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      country: ['', Validators.required],
      stateCode: ['+91', ''],
      contactNumber: ['', ''],
      interestedIn: ['', Validators.required],
      designation: ['', ''],
      description: ['', ''],
      confirmation: ['', Validators.required]
    });

    const data = {
      page: this.page,
      limit: 100,
      moduleName: 'PartnerWithUs'
    }
    this._websiteService.getInterestedIn(data).subscribe((data: { [x: string]: any; }) => {
      if (data['status'] == true) {
        this.interestedIn = data['data'][0]['data'];
      }
    });


    (function ($) {
      $(document).ready(function () {
        $('.logo-carousel').slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: false,
          pauseOnHover: false,
          responsive: [{
            breakpoint: 768,
            settings: {
              slidesToShow: 4
            }
          }, {
            breakpoint: 520,
            settings: {
              slidesToShow: 2
            }
          }]
        });
      });
    })(jQuery);
  }

  get f() { return this.partnerWithUsForm.controls; }

  onSubmit() {
    this.partnerWithUsFormSubmitted = true;
    this.partnerWithUsFormDisableSubmit = true;
    if (this.partnerWithUsForm.invalid) {
      this.partnerWithUsFormDisableSubmit = false;
      return;
    }
    const payload = {
      fullName: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.fullName),
      email: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.email),
      country: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.country),
      stateCode: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.stateCode),
      contactNumber: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.contactNumber),
      interestedIn: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.interestedIn),
      designation: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.designation),
      description: this._EncrDecr.set(environment.keyforencdec, this.partnerWithUsForm.value.description),
    }
    this.ngxLoader.start();
    this._websiteService.partnerWithUs(payload).subscribe((data: { [x: string]: any; }) => {
      if (data['status'] == true) {
        this.ngxLoader.stop();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'submitted successfully',
        }).then((result) => {
          this.partnerWithUsForm.reset();
          this.partnerWithUsFormSubmitted = false;
          this.partnerWithUsFormDisableSubmit = false;
        });
      } else {
        this.ngxLoader.stop();
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Not submitted successfully',
        });
        this.partnerWithUsFormDisableSubmit = false;
      }
    });
   
  }

}
