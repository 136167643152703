import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// import * as $ from 'jquery';
import { WebsiteService } from '../website.service';


declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {

  playbtn: HTMLElement;
  vid: HTMLElement;
  pauseTime: number;
  thirdblock: number;
  bgclor: string;

  channels: number = 0;

  campaignscount: number = 0;

  consumerscount: number = 0;

  convercount: number = 0;

  clientscount: number = 0;

  page: number = 1;
  limit: number = 10;
  limitForCampaigns: number = 100;

  banners: any;
  typeOfCampaigns: any;
  sliderImages: any = [];
  scrHeight: number;
  scrWidth: number;
  campaignCount: number;
  error: any;
  useCases: any[];
  useCaseCount: number;
  useCaseSliderImages: any = [];

  constructor(public video: ElementRef, private _websiteService: WebsiteService, private ngxLoader: NgxUiLoaderService) {
    this.startCounters();
   }

  ngOnInit() {
    this.stopCarousel();
    (function ($) {
      $(document).ready(function () {
        $('.logo-carousel').slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: false,
          pauseOnHover: false,
          responsive: [{
            breakpoint: 768,
            settings: {
              slidesToShow: 4
            }
          }, {
            breakpoint: 520,
            settings: {
              slidesToShow: 2
            }
          }]
        });
      });
    })(jQuery);

    this.getAllBanners(this.page, this.limit);
    this.getAllTypesOfCampaigns(this.page, this.limitForCampaigns);
    this.getAllUseCasesWeb();
    
    
  }

  startCounters() {
    var channelsstop = setInterval(() => {
      this.channels = this.channels === 20 ? 0 : this.channels + 1;
      if (this.channels === 20) {
        clearInterval(channelsstop);
      }
    }, 50)

    var campaignscountstop = setInterval(() => {
      this.campaignscount = this.campaignscount === 100 ? 0 : this.campaignscount + 1;
      if (this.campaignscount == 100) {
        clearInterval(campaignscountstop);
      }
    }, 50)

    var consumerscountstop = setInterval(() => {
      this.consumerscount = this.consumerscount === 10 ? 0 : this.consumerscount + 1;
      if (this.consumerscount == 10) {
        clearInterval(consumerscountstop);
      }
    }, 50)

    var convercountstop = setInterval(() => {
      this.convercount = this.convercount === 50 ? 0 : this.convercount + 1;
      if (this.convercount == 50) {
        clearInterval(convercountstop);
      }
    }, 50)

    var clientscountstop = setInterval(() => {
      this.clientscount = this.clientscount === 50 ? 0 : this.clientscount + 1;
      if (this.clientscount == 50) {
        clearInterval(clientscountstop);
      }
    }, 50);
  }

  stopCarousel() {
    $('.carousel').carousel({
      pause: "hover"
    })
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {

    let heightScroll = document.documentElement.scrollTop;
    console.log('heightScroll', heightScroll);
    if (heightScroll == 0) {
      this.startCounters();
    }


  };

  playPause() {
    var vid = <HTMLVideoElement>document.getElementById("myvideo");

    vid.play();

  }
  stopVideo() {
    var stopVid = <HTMLVideoElement>document.getElementById("myvideo");
    stopVid.pause();
    stopVid.currentTime = 0;
  }

  getAllBanners(page: any, limit: any) {
    const data = {
      page: page,
      limit: limit
    }
    this._websiteService.getAllWebBanners(data).subscribe((data: { [x: string]: any; }) => {
      if (!data['status']) {
        // this._toastr.error(data.msg, 'Warning!');
      }
      this.banners = data['data'][0];
      this.bgclor = this.banners['backgorundColor'];
      console.log('banners...', this.banners['backgorundColor'])
    });
  }


  getAllTypesOfCampaigns(page, limit) {
    const data = {
      page: page,
      limit: limit
    }
    this._websiteService.getAllTypesOfCampaigns(data).subscribe(
      (res) => {
        this.scrWidth = window.innerWidth;
        if (this.scrWidth > 1300) {
          this.campaignCount = 3;
        } else if (this.scrWidth < 1300 && this.scrWidth > 1025) {
          this.campaignCount = 3;
        } 
        else if (this.scrWidth <= 991) {
          this.campaignCount = 1;
        }
        this.typeOfCampaigns = this.chunks(res, this.campaignCount);
        console.log('this.typeOfCampaigns', this.typeOfCampaigns);
      },
      (err) => {
        this.error = err;
      }
    );
  }

  chunks(array, size) {
    let R = [];
    for (let i = 0, len = array['data'][0]['pagination'][0]['total']; i < len; i += size) {
      R.push(array['data'][0]['data'].slice(i, i + size));
    }
    for (let i = 0; i < R.length; i++) {
      this.sliderImages.push(i)
    }
    return R;
  }

  getAllUseCasesWeb() {
    this.ngxLoader.start();
    this._websiteService.getHomePageUseCasesWeb().subscribe(
      (res) => {
        this.scrWidth = window.innerWidth;
        if (this.scrWidth > 1300) {
          this.useCaseCount = 4;
        } else if (this.scrWidth < 1300 && this.scrWidth > 1025) {
          this.useCaseCount = 3;
        } 
        else if (this.scrWidth <= 991) {
          this.useCaseCount = 1;
        }
        this.useCases = this.chunksOfUseCases(res, this.useCaseCount);
        console.log('this.useCases', this.useCases);
      },
      (err) => {
        this.error = err;
      }
    );
    // this.ngxLoader.stop();
  }

  chunksOfUseCases(array, size) {
    let R = [];
    for (let i = 0, len = array['data'].length; i < len; i += size) {
      R.push(array['data'].slice(i, i + size));
    }

    for (let i = 0; i < R.length; i++) {
      this.useCaseSliderImages.push(i)
    }
    return R;
  }
}



