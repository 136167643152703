<!--Banner-->

<section>
  <div class="bannerSection" id="outerBanner">
    <div class="leftsideDiv">
      <div class="bannerText">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="connect">Drive results by automating<br> customer journey & delivering<br> great experiences.
            </h1>
            <p>The platform has everything (people, process and technology) that your campaigns need to succeed. If you
              have a great marketing idea, you would need the best execution platform for it to succeed.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ct">{{channels + "+"}}</span>
                <!-- <span>+</span> -->
              </div>
              <div class="prodTitle">
                Channels
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="cct">{{campaignscount + "+"}}</span>
              </div>
              <div class="prodTitle">
                Campaigns
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ccct">{{consumerscount + "M+"}}</span>
              </div>
              <div class="prodTitle">
                Consumers
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ccct">{{convercount + "M+"}}</span>
              </div>
              <div class="prodTitle">
                Conversations
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ccct">{{clientscount + "+"}}</span>
              </div>
              <div class="prodTitle">
                Clients
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightsideDiv" [ngStyle]="{'background':bgclor}">
      <img src="https://campaignbird.s3.ap-south-1.amazonaws.com/{{ banners?.bannerImg}}" alt="">
      <div class="innerrightDiv">
        <div class="campaignStatus">
          <h3>{{banners?.title}}</h3>
          <p>{{banners?.description}}</p>
          <ul>
            <li>Start Date: <span>{{banners?.startDate | date}}</span></li>
            <li>End Date: <span>{{banners?.endDate | date}}</span></li>
            <li>Status: <span class="live">{{banners?.status=='Active' ? 'Live' : 'Ended'}}</span></li>
          </ul>
          <a href="{{banners?.demoLink}}" class="takeDemo" target="_blank">Take a Demo</a>
          <a href="{{banners?.knowMoreLink}}" class="banMore" target="_blank">Know More</a>
        </div>
      </div>
    </div>
  </div>

  <!--Mobile banner-->
  <div class="bannermbSection">
    <div class="leftsidembDiv">
      <div class="bannerText">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="connect">Drive results by automating customer journey & delivering great experiences.</h1>
            <p>The platform has everything (people, process and technology) that your campaigns need to succeed. If you
              have a great marketing idea, you would need the Best execution platform for it to succeed.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ct">{{channels + "+"}}</span>
                <!-- <span>+</span> -->
              </div>
              <div class="prodTitle">
                Channels
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="cct">{{campaignscount + "+"}}</span>
              </div>
              <div class="prodTitle">
                Campaigns
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ccct">{{consumerscount + "M+"}}</span>
              </div>
              <div class="prodTitle">
                Consumers
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ccct">{{convercount + "M+"}}</span>
              </div>
              <div class="prodTitle">
                Conversations
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3 text-center">
            <div class="prodInfo">
              <div class="count">
                <span id="ccct">{{clientscount + "+"}}</span>
              </div>
              <div class="prodTitle">
                Clients
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightsidembDiv" style="background: #d8eff7;">
      <img src="https://campaignbird.s3.ap-south-1.amazonaws.com/{{ banners?.bannerImg}}" alt="">
      <div class="innerrightmbDiv">
        <div class="campaignStatus">
          <!-- <h3>{{banners?.title}}</h3>
          <p>{{banners?.description}}</p> -->
          <ul>
            <li>Start Date: <span>{{banners?.startDate | date}}</span></li>
            <li>End Date: <span>{{banners?.endDate | date}}</span></li>
            <li>Status: <span class="live">{{banners?.status=='Active' ? 'Live' : 'Ended'}}</span></li>
          </ul>
          <a href="{{banners?.demoLink}}" class="takeDemo">Take a Demo</a>
          <a href="{{banners?.knowMoreLink}}" class="banMore">Know More</a>
        </div>
      </div>
    </div>

  </div>
</section>

<!--Logos-->
<section>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-center mt-5" data-aos="fade-up" data-aos-duration="500">A few of our clients </h1>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <section class="logo-carousel slider" data-arrows="true">
          <div class="slide logo"><img src="/assets/logos/img1.png"></div>
          <div class="slide logo"><img src="/assets/logos/img3.png"></div>
          <div class="slide logo"><img src="/assets/logos/img2.jpg"></div>
          <div class="slide logo"><img src="/assets/logos/img4.png"></div>
          <div class="slide logo"><img src="/assets/logos/img5.png"></div>
          <!-- <div class="slide logo"><img src="/assets/logos/img6.png"></div> -->
          <div class="slide logo"><img src="/assets/logos/img7.png"></div>
          <div class="slide logo"><img src="/assets/logos/img8.png"></div>
          <div class="slide logo"><img src="/assets/logos/img9.png"></div>
          <div class="slide logo"><img src="/assets/logos/img10.png"></div>
          <div class="slide logo"><img src="/assets/logos/img11.jpg"></div>
          <div class="slide logo"><img src="/assets/logos/img12.png"></div>
          <div class="slide logo"><img src="/assets/logos/img13.jpg"></div>
          <div class="slide logo"><img src="/assets/logos/img14.png"></div>
          <div class="slide logo"><img src="/assets/logos/img15.png"></div>
          <div class="slide logo"><img src="/assets/logos/img16.png"></div>
          <div class="slide logo"><img src="/assets/logos/img17.png"></div>
          <div class="slide logo"><img src="/assets/logos/img18.png"></div>
          <div class="slide logo"><img src="/assets/logos/img19.png"></div>
          <div class="slide logo"><img src="/assets/logos/img20.png"></div>
          <div class="slide logo"><img src="/assets/logos/img21.png"></div>
        </section>
      </div>
    </div>
  </div>
</section>

<!--Type of campaign Desktop-->
<section class=" ourSolutionDesktop ">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 id="test" class=" " data-aos="fade-up" data-aos-duration="500">Types of campaigns</h1>
      </div>
    </div>
    <div class="row solutioninnerCard">
      <div class="col-sm-12">
        <div id="carouselTypeCampaign" class="carousel slide" data-ride="carousel" data-interval="5000"
          (mouseover)="stopCarousel()">
          <ol class="carousel-indicators usecaseCarousel">
            <li data-target="#carouselTypeCampaign" *ngFor="let li of sliderImages; let i = index"
              [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor='let typeOfCampaign of typeOfCampaigns; let i = index'
              [ngClass]="{'active': i == 0}">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 outerCommonHeight" data-aos="fade-up"
                  *ngFor="let campaign of typeOfCampaign; let i = index">
                  <div class="oursoultionCard">
                    <div class="rectangle " [ngClass]="[i%2 == 1 ? 'blue' : '',  i%2 == 0 ? 'pink' : '']">
                      <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+campaign?.iconImg" alt="No Icon">
                    </div>
                    <div class="commonHeight">
                      <h4>{{campaign?.title}} </h4>
                      <p>{{campaign?.description}}</p>
                    </div>
                    <div class="text-center knowMore">
                      <a [routerLink]="['/use-case', campaign?._id]">Know More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Type of campaign Mobile-->
<section class="ourSolutionMobile">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 data-aos="fade-up" data-aos-duration="1000">Type of campaigns</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="carouselOurSolution" class="carousel slide" data-ride="carousel" data-interval="4000"
          (mouseover)="stopCarousel()">
          <ol class="carousel-indicators">
            <li data-target="#carouselOurSolution" *ngFor="let li of sliderImages; let i = index"
              [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor='let typeOfCampaign of typeOfCampaigns; let i = index'
              [ngClass]="{'active': i == 0}">
              <div class="outerCommonHeight" *ngFor="let campaign of typeOfCampaign; let i = index">
                <div class="oursoultionCard text-center">
                  <div class="rectangle pink">
                    <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+campaign?.iconImg" alt="No Icon">
                  </div>
                  <h4>{{campaign?.title}}</h4>
                  <p>{{campaign?.description}}</p>
                  <div class="text-center knowMore">
                    <a [routerLink]="['/use-case', campaign?._id]">Know More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Use Cases Desktop-->
<section class="useCasesDesktop">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="tradeHead" data-aos="fade-up" data-aos-duration="500">Use cases</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="3000"
          (mouseover)="stopCarousel()">
          <ol class="carousel-indicators usecaseCarousel">
            <li data-target="#carouselExampleIndicators" *ngFor="let li of useCaseSliderImages; let i = index"
              [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor='let useCase of useCases; let i = index' [ngClass]="{'active': i == 0}">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight"
                  *ngFor="let uc of useCase; let i = index">
                  <div class="outerUsecase">
                    <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+uc?.icon[0]" alt="No Image">
                    <h4>{{uc._id}}</h4>
                    <!-- <h6> (WhatsApp)</h6> -->
                    <div class="text-center caseknowMore"><a [routerLink]="['/usecase', uc.subCategoryId]">Know More</a>
                    </div>
                    <div class="line">
                    </div>
                  </div>
                </div>
                <!-- <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                  <div class="outerUsecase">
                    <img src="assets/usecase/2.png" alt="Web Templates">
                    <h4>Quiz or Puzzle </h4>
                    <h6>(WhatsApp)</h6>
                    <div class="text-center caseknowMore"><a href="">Know More</a></div> 
                    <div class="line">
                      
                    </div> 
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
                  <div class="outerUsecase">
                    <img src="assets/usecase/3.png" alt="AR/VR">
                    <h4>Cashback or Instant Gratification</h4>
                    <div class="text-center caseknowMore"><a href="">Know More</a></div> 
                    <div class="line">
                      
                    </div> 
                  </div>
                </div> -->
              </div>
            </div>
            <!-- <div class="carousel-item">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="500">
                  <div class="outerUsecase">
                    <img src="assets/usecase/4.png" alt="Messaging apps">
                    <h4>Changing Habits</h4>
                    <h5>&nbsp;</h5>
                    <div class="text-center caseknowMore"><a href="">Know More</a></div> 
                    <div class="line">
                      
                    </div> 
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
                  <div class="outerUsecase">
                    <img src="assets/usecase/5.png" alt="SMS">
                    <h4>Share Product Recipe and Get Reward</h4>
                   
                    <div class="text-center caseknowMore"><a href="">Know More</a></div> 
                    <div class="line">
                      
                    </div> 
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1500">
                  <div class="outerUsecase">
                    <img src="assets/usecase/6.png" alt="RCS">
                    <h4>Retailer Visibility Campaign</h4>
                    <h6>(For Retailers and Sales People)</h6>
                    <div class="text-center caseknowMore"><a href="">Know More</a></div> 
                    <div class="line">
                    </div> 
                  </div>
                </div>
              </div>
            </div> -->
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="viewAll">
          <a [routerLink]="['/use-case']">View All</a>
        </div>
      </div>
    </div>
    <ngx-ui-loader [loaderId]="'loader-02'"></ngx-ui-loader>
  </div>
</section>

<!--Use Cases Mobile-->
<section class="useCasesMobile">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="tradeHead">Use cases</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="carouselCasesMobile" class="carousel slide" data-ride="carousel" data-interval="3000"
          (mouseover)="stopCarousel()">
          <ol class="carousel-indicators usecaseCarousel">
            <li data-target="#carouselCasesMobile" *ngFor="let li of useCaseSliderImages; let i = index"
              [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor='let useCase of useCases; let i = index' [ngClass]="{'active': i == 0}">
              <div class="row">
                <div class="col-sm-12 text-center outerCommonHeight" *ngFor="let uc of useCase; let i = index">
                  <div class="outerUsecase">
                    <img [src]="'https://campaignbird.s3.ap-south-1.amazonaws.com/'+uc?.icon[0]" alt="No Image">
                    <h4>{{uc._id}}</h4>
                    <div class="text-center caseknowMore"><a [routerLink]="['/usecase', uc.subCategoryId]">Know More</a>
                    </div>
                    <div class="line">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 text-center outerCommonHeight">
                  <div class="outerUsecase">
                    <img src="assets/usecase/2.png" alt="Web Templates">
                    <h4>Quiz or Puzzle </h4>
                    <h6>(WhatsApp)</h6>
                    <div class="text-center caseknowMore"><a href="">Know More</a></div>
                    <div class="line">

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 text-center outerCommonHeight">
                  <div class="outerUsecase">
                    <img src="assets/usecase/3.png" alt="AR/VR">
                    <h4>Cashback or Instant Gratification</h4>
                    <div class="text-center caseknowMore"><a href="">Know More</a></div>
                    <div class="line">

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-sm-12 text-center outerCommonHeight">
                <div class="outerUsecase">
                  <img src="assets/usecase/4.png" alt="Messaging apps">
                  <h4>Changing Habits</h4>
                  <h5>&nbsp;</h5>
                  <div class="text-center caseknowMore"><a href="">Know More</a></div>
                  <div class="line">

                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-sm-12 text-center outerCommonHeight">
                <div class="outerUsecase">
                  <img src="assets/usecase/5.png" alt="SMS">
                  <h4>Share Product Recipe and Get Reward</h4>

                  <div class="text-center caseknowMore"><a href="">Know More</a></div>
                  <div class="line">

                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="col-sm-12 text-center outerCommonHeight">
                <div class="outerUsecase">
                  <img src="assets/usecase/6.png" alt="RCS">
                  <h4>Retailer Visibility Campaign</h4>
                  <h6>(For Retailers and Sales People)</h6>
                  <div class="text-center caseknowMore"><a href="">Know More</a></div>
                  <div class="line">
                  </div>
                </div>
              </div>
            </div> -->
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="viewAll">
          <a [routerLink]="['/use-case']">View All</a>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Reward Section-->
<section class="outerRewards">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-center mt-5" data-aos="fade-up" data-aos-duration="500">Rewards</h1>
        <p class="text-center">Make the rewarding experience delightful for your customers</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="rewards">
          <ul>
            <li>
              <figure>
                <img src="assets/rewards/1.png" alt="UPI Money Transfer">
                <figcaption>
                  UPI Money Transfer
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="assets/rewards/2.png" alt="e-Vouchers ">
                <figcaption>e-Vouchers</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="assets/rewards/3.png" alt="Paytm">
                <figcaption>Digital Cashbacks</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="assets/rewards/4.png" alt="Amazon Pay">
                <figcaption>Experiential Rewards</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="assets/rewards/5.png" alt="Physical">
                <figcaption>Physical Gifts
                  <div class="gifts">Gifts (4000+)</div>
                </figcaption>
              </figure>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Technologies Desktop-->
<section class="outerTrade techDesktop">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="tradeHead" data-aos="fade-up" data-aos-duration="500">Technologies</h1>
        <p class="tradeDes" data-aos="fade-up" data-aos-duration="1000">All technologies under one roof </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="carouseltechDesktop" class="carousel slide" data-ride="carousel" (mouseover)="stopCarousel()">
          <ol class="carousel-indicators usecaseCarousel">
            <li data-target="#carouseltechDesktop" data-slide-to="0" class="active"></li>
            <li data-target="#carouseltechDesktop" data-slide-to="1"></li>
            <li data-target="#carouseltechDesktop" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/1.png" alt="QR Code">
                    <h4>QR Code</h4>
                    <p>The Quick Response Code (QR Code) is a game-changing technology for connecting a brand's physical
                      presence with its online presence.</p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="800">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/2.png" alt="Web ">
                    <h4>Web</h4>
                    <p>Campaign website with all campaign related info viz. how to participate, terms and conditions,
                      privacy policy, rewards, winner gratification process, participation stats, winner lists, winner
                      search etc.</p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/3.png" alt="Campaign Microsite">
                    <h4>Campaign Microsite</h4>
                    <p>Website with beautiful web templates and all campaign related info viz. how to participate, terms
                      and conditions, privacy policy, rewards, winner gratification process, participation stats, winner
                      lists, winner search etc.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/4.png" alt="Messaging apps">
                    <h4>Messaging Apps</h4>
                    <p>Messaging apps have huge penetration and user journeys can be uniquely created for these apps.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/5.png" alt="SMS">
                    <h4>SMS</h4>
                    <p>Text messaging is a highly efficient and direct method of reaching out to customers. SMS can be
                      used to send alerts, offers, discounts, or promotions to prospective and existing customers.</p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="1500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/6.png" alt="RCS">
                    <h4>RCS</h4>
                    <h6>(The next-generation SMS)</h6>
                    <p>An advanced version of SMS where multimedia messages can be delivered in inbox and has a higher
                      open rate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/7.png" alt="Email">
                    <h4>Email</h4>
                    <p>Email marketing enables relevant and personalised communications to be sent. This can assist in
                      the development of long-term consumer ties. </p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="800">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/8.png" alt="Missed Call / IVR">
                    <h4>Missed Call / IVR</h4>
                    <p>A Missed Call Campaign is an innovative way to reach out to a targeted audience or prospect. It
                      requires a simple action by allowing users to reach out at no cost to them.</p>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 text-center outerCommonHeight" data-aos="fade-up"
                  data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/9.png" alt="AR/VR">
                    <h4>API Integration</h4>
                    <p>Customized third party API integration</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Technologies Mobile-->
<section class="outerTrade techMobile">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="tradeHead" data-aos="fade-up" data-aos-duration="500">Technologies</h1>
        <p class="tradeDes" data-aos="fade-up" data-aos-duration="1000">All technologies under one roof </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div id="carouseltechMobile" class="carousel slide" data-ride="carousel" (mouseover)="stopCarousel()">
          <ol class="carousel-indicators usecaseCarousel">
            <li data-target="#carouseltechMobile" data-slide-to="0" class="active"></li>
            <li data-target="#carouseltechMobile" data-slide-to="1"></li>
            <li data-target="#carouseltechMobile" data-slide-to="2"></li>
            <li data-target="#carouseltechMobile" data-slide-to="3"></li>
            <li data-target="#carouseltechMobile" data-slide-to="4"></li>
            <li data-target="#carouseltechMobile" data-slide-to="5"></li>
            <li data-target="#carouseltechMobile" data-slide-to="6"></li>
            <li data-target="#carouseltechMobile" data-slide-to="7"></li>
            <li data-target="#carouseltechMobile" data-slide-to="8"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/1.png" alt="QR Code">
                    <h4>QR Code</h4>
                    <p>The Quick Response Code (QR Code) is a game-changing technology for connecting a brand's physical
                      presence with its online presence.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/2.png" alt="Web ">
                    <h4>Web</h4>
                    <p>Campaign website with all campaign related info viz. how to participate, terms and conditions,
                      privacy policy, rewards, winner gratification process, participation stats, winner lists, winner
                      search etc.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/3.png" alt="Campaign Microsite">
                    <h4>Campaign Microsite</h4>
                    <p>Website with beautiful web templates and all campaign related info viz. how to participate, terms
                      and conditions, privacy policy, rewards, winner gratification process, participation stats, winner
                      lists, winner search etc.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/4.png" alt="Messaging apps">
                    <h4>Messaging Apps</h4>
                    <p>Messaging apps have huge penetration and user journeys can be uniquely created for these apps.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/5.png" alt="SMS">
                    <h4>SMS</h4>
                    <p>Text messaging is a highly efficient and direct method of reaching out to customers. SMS can be
                      used to send alerts, offers, discounts, or promotions to prospective and existing customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/6.png" alt="RCS">
                    <h4>RCS</h4>
                    <h6>(The next-generation SMS)</h6>
                    <p>An advanced version of SMS where multimedia messages can be delivered in inbox and has a higher
                      open rate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="500">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/7.png" alt="Email">
                    <h4>Email</h4>
                    <p>Email marketing enables relevant and personalised communications to be sent. This can assist in
                      the development of long-term consumer ties. </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="800">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/8.png" alt="Missed Call / IVR">
                    <h4>Missed Call / IVR</h4>
                    <p>A Missed Call Campaign is an innovative way to reach out to a targeted audience or prospect. It
                      requires a simple action by allowing users to reach out at no cost to them.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-12 col-12 text-center outerCommonHeight" data-aos="fade-up" data-aos-duration="1000">
                  <div class="outerUltimate">
                    <img src="assets/technologies-icon/9.png" alt="AR/VR">
                    <h4>API Integration</h4>
                    <p>Customized third party API integration</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Additional Service-->
<section class="addService">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center" data-aos="fade-up" data-aos-duration="500">Additional services</h1>

      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="media hiddenOverflow">
          <div class="rectangle pinks" data-aos="fade-up" data-aos-duration="1000">
            <img src="assets/1.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Process Audit</h5>
            <p data-aos="fade-up" data-aos-duration="1500">A complete audit of the whole program by our vendor. Get a
              3rd party audit certificate.</p>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="media hiddenOverflow">
          <div class="rectangle blues" data-aos="fade-up" data-aos-duration="1000">
            <img src="assets/2.png">
          </div>
          <div class="media-body">
            <h5 class="mt-0" data-aos="fade-up" data-aos-duration="1300">Data Management Platform</h5>
            <p data-aos="fade-up" data-aos-duration="1500">A central hub to store, manage and analyze all your campaign
              and audience data.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Advantage-->
<section class="advant">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center" data-aos="fade-up" data-aos-duration="500">Why work with us?</h1>

      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="row featurette d-flex">
          <div class="col-md-5 offset-md-1 order-md-2 manageCol first ">
            <ul>
              <li data-aos="fade-up" data-aos-duration="1600">
                Less time to market
              </li>
              <li data-aos="fade-up" data-aos-duration="1700"> Low overall cost of campaign
              </li>
              <li data-aos="fade-up" data-aos-duration="1800">Real time campaign tracking</li>
              <li data-aos="fade-up" data-aos-duration="1900">24x7 support during live campaigns</li>
              <li data-aos="fade-up" data-aos-duration="2000">Flexible cloud infrastructure that scales automatically
                with your campaigns</li>
              <li data-aos="fade-up" data-aos-duration="2100">Encrypted customer data for better data protection</li>
              <li data-aos="fade-up" data-aos-duration="2200">A data management platform that can be used to build
                awareness on your past customer data</li>
            </ul>
          </div>
          <div class="col-md-5 order-md-1 manageCol last text-center" data-aos="fade-up" data-aos-duration="1500">
            <img src="assets/Advantages.png" class="adv" alt="Some of the key advantages of working with us">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Case Studies Desktop-->
<section class="outerRewards caseDesktop">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-center mt-5 mb-5" data-aos="fade-up" data-aos-duration="500">Case studies</h1>
      </div>
    </div>
    <app-case-study-data></app-case-study-data>
  </div>
</section>

<!--Case Studies Mobile-->
<section class="outerRewards caseMobile">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-center mt-5 mb-5" data-aos="fade-up" data-aos-duration="500">Case studies</h1>
      </div>
    </div>
    <app-case-study-data></app-case-study-data>
  </div>
</section>

<!-- <ngx-ui-loader></ngx-ui-loader> -->