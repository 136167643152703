import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EncrDecrService } from '../shared/EncrDecrService';
import { WebsiteService } from '../website.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-view-case-study',
  templateUrl: './view-case-study.component.html',
  styleUrls: ['./view-case-study.component.css']
})
export class ViewCaseStudyComponent implements OnInit {
  caseStudyId: any;
  caseStudy: any;

  constructor(private _websiteService: WebsiteService, private _route: ActivatedRoute, private _EncrDecr: EncrDecrService, private _router: Router, private ngxLoader: NgxUiLoaderService) {
    this._route.params.subscribe(params => {
      this.caseStudyId = params["caseStudyId"];
    });

  }

  ngOnInit(): void {
    this.ngxLoader.start();
    console.log('this.caseStudyId', this.caseStudyId);
    const data = {
      caseStudyId: this._EncrDecr.set(environment.keyforencdec, this.caseStudyId)
    }
    this._websiteService.getCaseStudyByIdWeb(data).subscribe((data: { [x: string]: any; }) => {
     
      if (data['status']) {
        this.caseStudy = data['data'].length != 0 ? data['data'] : [];
        console.log('this.caseStudy',this.caseStudy);
         this.ngxLoader.stop();
      }
    });
  }

}
